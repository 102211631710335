import store from "store"

export default {
    getLocalStore() {
        return store.get('mmc-proj-publish')
    },
    setLocalStore(projObj) {
        store.set('mmc-proj-publish', projObj)
    },
    removeLocalStore() {
        store.remove('mmc-proj-publish')
        store.remove('mmc-proj-autoform')
    },
    stripNonNumber(str) {
        if (!str) return '';

        return (str + '').replace(/\D+/g, '');
    },
    checkPhone(value) {
        var PHONE = /^1\d{10}$/;



        if (!PHONE.test(value)) {
            return false
        }

        return true
    },
    checkTitle(value) {


        if (value.length < 4) {
            return false
        } else if (value.length > 40) {
            return false
        } else {

            return true
        }
    },
    checkMoney(value) {


        if (value.length < 4) {
            return false
        } else if (value.length > 40) {
            return false
        } else {

            return true
        }
    },
    checkIntroduction(value) {
        if (value.length < 20) {
            return 0;
        } else if (value.length > 2000) {
            return 1;
        } else {
            return 2;
        }
    },
    checkForm() {

    }
}
