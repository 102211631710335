const mathAgeTitle = res => {
    /**
     * @param disease  疾病
     * @param forWhomName  为谁筹款 （关系 父母 孩子）
     * @param name  患者姓名
     * @param pronounVal  患者性别 1 他 2 她
     * @param age  患者年龄
     * **/

    const { disease, forWhomName, name, age, pronounVal} = res;
    const pronoun = ['', '他', '她'][pronounVal];

    const selfTemplates = {
        'toddler': [
            {code:30,text:`叔叔阿姨救救我，我要被${disease }这个怪兽打倒了！`},
            {code:31,text:`我才${age == 0 ? '不到一' : age }岁，不幸身患${disease }，求求叔叔阿姨救救我！`},
            {code:32,text:`${age == 0 ? '不到一' : age }岁儿童身患${disease }，恳请请大家伸出援手！`},
        ],
        'teenager':[
            {code:37,text:`我才${age }岁，是家里的希望，恳请大家救救我！`},
            {code:38,text:`我才${age }岁，我不想离开这个世界，我想活着！`},
            {code:39,text:`我才${age }岁，我想和正常孩子一样上学！求求大家救救我！`},
            {code:40,text:`我才${age }岁，我还想上学，我还想看看这个美丽的世界！`}
        ],
        'adult':[
            {code:45,text:`${age }岁身患${disease }，恳求大家救救我，家里不能没有我！`},
            {code:46,text:`我才${age }岁不幸身患${disease }，我想活着！恳请大家救救我！`}
        ],
        'seniors':[
            {code:51,text:`不幸身患${disease }，家里上有老下有小我不能倒下！大家救救我！`},
            {code:52,text:`我是家里的顶梁柱不幸身患${disease }，家里不能没有我！大家救救我！`}
        ]

    };
    const childTemplates = {
        'toddler': [
            {code:33,text:`我的${forWhomName }还这么小，饱受病痛折磨！求求救救${pronoun }！`},
            {code:34,text:`恳请好心人救救我那身患${disease }的${forWhomName }！`},
        ],
        'teenager':[
            {code:41,text:`跪求好心人帮助我可怜的${forWhomName }！${pronoun }才${age }岁人生才刚开始！`},
            {code:42,text:`我的${forWhomName }才${age }岁，不幸身患${disease }，恳求大家救救${pronoun }！`},
        ],
        'adult':[
            {code:49,text:`${forWhomName }${name }才${age }岁，不幸突遭厄运，跪求大家救救${pronoun }！`},
            {code:50,text:`我${forWhomName }心地善良，不幸身患${disease }生命垂危，希望好心人救救${pronoun }！`}
        ],

    };
    const parentTemplates = {
        'seniors':[
            {code:53,text:`${forWhomName }为家奉献了一切，却身患${disease }，求大家救救${pronoun }！`},
            {code:54,text:`${forWhomName }不幸身患${disease } ，我们一家不能没有${pronoun }！`},
            {code:55,text: `${forWhomName }辛苦了半辈，不幸身患${disease }，大家救救我们一家吧！`},
            {code:56,text:`我${forWhomName }才${age }岁，不幸身患${disease }，求大家救救${pronoun }！`}

        ]

    };
    const coupleTemplates = {
        'adult':[
            {code:47,text:`${forWhomName }才${age }岁不幸身患${disease }，家里不能没有${pronoun }！跪求好心人帮助！`},
            {code:48,text:`${forWhomName }才${age }岁不幸身患${disease }，急需治病钱，大家救救${pronoun }！`}
        ],
        'seniors':[
            {code:57,text:`夫妻相濡以沫几十年，此刻我只想挽救${pronoun }的命！请大家救救${pronoun }！`},
            {code:58,text:`${forWhomName }不幸身患${disease }，急需治病钱，大家救救${pronoun }！`},
        ]

    };
    const otherTemplates = {
        'toddler': [
            {code:35,text:`可怜的${name }还没上学，就要被病魔带走了！求求大家救救${pronoun }！`},
            {code:36,text:`救救这个可怜的孩子吧，身患${disease }却无钱看病！`},
        ],
        'teenager':[
            {code:43,text:`${pronoun }的人生正是美好的时候，却不幸患${disease }，请大家帮${pronoun }一把！`},
            {code:44,text:`${age }岁阳光${ name }不幸身患${disease }，恳求大家伸出援手！`},
        ]

    };

    const templateAgeData = {
        self: selfTemplates,
        parent: parentTemplates,
        child: childTemplates,
        couple: coupleTemplates,
        other: otherTemplates,
    }
    return templateAgeData
}
export default mathAgeTitle;
