export const validAge = () => {
    return (rule, value) => {
        if (value === "") {
            return '请输入患者年龄'
        } else if(Number(value) < 999 && Number(value) >= 0) {
            return ''
        }else if(Number(value) >= 999){
            return '请输入正确的患者年龄'
        }else{
            return ''
        }
    };
}

export const validName = () => {
    return (rule, value) => {
        if (value === "" || !value) {
            return '请输入患者姓名'
        } else if(value.length >= 2 && value.length <=10) {
            return ''
        }else if(value.length > 10 || value.length < 2){
            return '请输入正确的患者姓名'
        }
    };
}

export const validMoney = () => {
    return (rule, value) => {
        if (value === "" || !value) {
            return '请输入目标金额'
        } else if(value >= 1 && value <= 9999999) {
            return ''
        }else if(value > 9999999){
            return '最大筹款金额为9999999'
        }else if(value < 1 ) {
            return '筹款金额不能为0'
        }
    };
}

export const validSpendMoney = () => {
    return (rule, value) => {
        if (value === "" || !value) {
            return '请输入已花费金额'
        } else if(value >= 1 && value <= 999999) {
            return ''
        }else if(value > 999999){
            return '最大已花费金额为999999'
        }else if(value < 1 ) {
            return '已花费金额不能为0'
        }
    };
}
