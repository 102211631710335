import Vuex from 'vuex'
import tool from "./tool"
import api from './api'
import {
    utils
} from '@/common/js/mmcModule';

const storePublish = new Vuex.Store({
    state: {
        projObj: {
            "title": "",
            "images": [

            ],
            "introduction": "",
            "raisefunds_number": '',
            "referee_phone": "",
        },
        counselor: {
            name: '',
            phone: '',
            id: '',
        },
        dataOK: false,
    },
    mutations: {
        initState(state) {
            this.state.dataOK = true;
            let localStore = tool.getLocalStore();
            if (localStore) {
                this.state.projObj = localStore;
            }

        },
        changeImage(state, arr) {

            this.state.projObj.images = arr;
            tool.setLocalStore(this.state.projObj)

        },
        changeCounselor(state, obj) {
            this.state.counselor.name = obj.name;
            this.state.counselor.phone = obj.mobile;
            this.state.counselor.id = obj.id;
        },
        changeState(state) {
            tool.setLocalStore(this.state.projObj)
        }
    },
    actions: {
        submitProj(context, router) {
            tool.checkForm();
            // context.commit('increment')
            // console.log(this.state.projObj)
            let lastObj = JSON.parse(JSON.stringify(this.state.projObj));
            lastObj.raisefunds_number *= 100;
            if (this.state.counselor.id) {
                lastObj.counselor_id = this.state.counselor.id;
                lastObj.referee_phone = '';
            }

            // lastObj.raisefunds_number=0;
            // console.log(1,lastObj,22)
            showLoading();
            api.submitProj(lastObj).then((res) => {
                // console.log(44,res,55);
                hideLoading();
                // alertMsg(res.msg);
                if (res.code == 0) {
                    tool.removeLocalStore()
                    router.replace('/fund/publish/success?projuuid=' + res.data.uuid)
                }
            })
        },

        getCounselor(context, initTextarea) {

            showLoading("加载中");
            api.getCounselor().then(res => {
                hideLoading();
                this.commit('initState');
                initTextarea()
                if (res.code == 0 && res.data.id > 0) {
                    this.commit('changeCounselor', res.data)
                    console.log(res.data)
                }
            })
        }

    }
})

export default storePublish;