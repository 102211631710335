const mathTitle = res => {
    /**
     * @param disease  疾病
     * @param forWhomName  为谁筹款 （关系 父母 孩子）
     * @param name  患者姓名
     * @param pronounVal  患者性别 1 他 2 她
     * **/
    const { disease, forWhomName, name, pronounVal} = res;
    const pronoun = ['', '他', '她'][pronounVal];


    const selfTemplates = [
        { code: 1, text: `助人为善，感恩有你，恳请大家帮帮我！` },
        { code: 2, text: `还想要再努力一下，还想要活下去！` },
        { code: 3, text: `帮帮我！让我在人世上活下去！` },
        {code:15,text:`不幸身患${ disease }，花光积蓄走投无路，我想活下去！大家救救我！`},
        {code:16,text:`不幸身患${ disease }，舍不得离开家人，希望大家伸出援手救救我！`},
        {code:59,text:`坚强是我与疾病斗争的宣言，请大家伸出援手，给我一份生的希望！`},
        {code:60,text:`苦苦与病魔抗争只为活下去，求求大家救救可怜的我！`},
    ];
    const childTemplates = [
        { code: 4, text: `${name}，别离开爸爸妈妈！我们一起战胜疾病！` },
        { code: 5, text: `恳求好心人救救我的${forWhomName}${name}，谢谢你们了！` },
        { code: 6, text: `这个世界那么美好，${forWhomName}${name}你一定要撑住！`},
        {code: 23,text:`${ forWhomName }别离开爸爸妈妈！我们不能失去你！`},
        {code: 24,text:`${ forWhomName }是我的一切，病魔就要将${ pronoun }夺走了，求求大家救救${ pronoun }！`},
        {code: 25,text:`每天在医院以泪洗面，求求大家救救我的${ forWhomName }吧！`},
        {code: 26,text:`${ forWhomName }很坚强，在病床上笑着说：“爸爸妈妈我不疼”！`},
        {code: 27,text:`孩子！你一定要振作，我们不会放弃你的！`},
    ];
    const coupleTemplates = [
        { code: 7, text: `恳求好心人救救我的${ forWhomName }${ name }，谢谢你们了！` },
        { code: 8, text: `救救我患有${ disease }的${ forWhomName }${ name }！` },
        { code: 9, text: `这个世界那么美好，${ forWhomName }${ name }你一定要撑住！` },
        { code: 10, text: `给${ forWhomName }${ name }一个活下去的希望！` },
        {code: 20,text:`${ name }，这个家不能没有你！我们一起战胜病魔！`},
        {code: 21,text:`结婚多年相濡以沫，如今${ pronoun }却患上${ disease }，恳求大家的帮助！`},
        {code: 22,text:`请大家救救我身患${ disease }的${ forWhomName }，这个家不能没有${ pronoun }！`},
    ];
    const otherTemplates = [
        { code: 11, text: `${ name }，你要坚强，一切都会好起来的！` },
        { code: 12, text: `生命接力！希望大家可以帮助${ name }活下去。` },
        {code: 28,text:`${ name }为人善良，不幸身患${ disease }，急需社会救助！`},
        {code: 29,text:`恳请大家救救${ name }，给${ pronoun }活下去的希望！`},
    ];
    const parentTemplates = [
        { code: 13, text: `恳求好心人救救我的${ forWhomName }${ name }，谢谢你们了！` },
        { code: 14, text: `给${ forWhomName }${ name }一个活下去的希望！` },
        {code: 17,text: `${ forWhomName }为我操劳一生，我却没钱为${ pronoun }治病！恳求大家的帮助！`},
        {code: 18,text:`家境贫寒，请大家救救我那身患${ disease }的${ forWhomName }！`},
        {code: 19,text:`${ forWhomName }${ name }生命垂危，恳请各位爱心人士救救${ pronoun }！`},
    ];

    const templateData = {
        self: selfTemplates,
        parent: parentTemplates,
        child: childTemplates,
        couple: coupleTemplates,
        other: otherTemplates,
    }
    return templateData
}

export default mathTitle;
