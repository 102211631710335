<template>
    <input
        class="ai-input"
        type="text"
        @input="handleInput"
        @blur="handleBlur"
        :value="defaultValue"
    >
</template>

<script>
import Emitter from '../js/emitter.js'
export default {
    name: "aiInput",
    mixins: [Emitter],
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            defaultValue: this.value
        }
    },
    watch:{
        value (val) {
            this.defaultValue = val;
        }
    },
    methods:{
        /**
         * change 事件
         * @param event
         */
        handleInput(event){
            // 当前model 赋值
            this.defaultValue = event.target.value;
            // vue 原生的方法 return 出去
            this.$emit('input',event.target.value);
            // 将当前的值发送到 aiFormItem 进行校验
            this.dispatch('aiFormItem','on-form-change',event.target.value)
        },
        /**
         * blur 事件
         * @param event
         */
        handleBlur(event){
            this.$emit('blur',event.target.value);
            // 将当前的值发送到 aiFormItem 进行校验
            this.dispatch('aiFormItem','on-form-blur',event.target.value)
        }
    }
}
</script>
<style scoped lang="less">
.ai-input {
    border: none;
    text-align: right;
    font-size: 16px;
    &::-webkit-input-placeholder{
        color: #ccc;
    }
    &::-moz-input-placeholder{
        color: #ccc;
    }
    &::-ms-input-placeholder{
        color: #ccc;
    }
}
</style>
