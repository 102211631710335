import mathTitle from '../date/titleTemplate';
import mathAgeTitle from '../date/titleAgeTemplate';
import randomDec from '../date/decTemplate';
import randomAgeDec from '../date/decAgeTemplate';

/**
 * 关系枚举
 * **/
const relationArr = {
    1: 'self',
    2: 'parent',
    3: 'parent',
    4: 'couple',
    5: 'couple',
    6: 'child',
    7: 'child',
    8: 'other',
};

/**
 * 选择年龄
 * **/
function ageType(age) {
    if (age >= 0 && age < 6) {
        return 'toddler';
    }
    if (age >= 6 && age < 20) {
        return 'teenager';
    }
    if (age >= 20 && age < 40) {
        return 'adult';
    }
    if (age >= 40) {
        return 'seniors';
    }
    return '';
};

/**
 * 获取title逻辑
 * **/
function intellTitleFn(e) {
    const { forWhom, age } = e;
    const item = relationArr[forWhom];
    let titleArr = mathTitle(e)[item];
    const patientAgeType = ageType(age);
    /**
     * 获取对应年龄段的标题进行合并
     * **/
    if(forWhom == 1) {
        titleArr = titleArr.concat(mathAgeTitle(e)[item][patientAgeType])
    }else if((forWhom == 2 || forWhom == 3) && patientAgeType === 'seniors') {
        titleArr = titleArr.concat(mathAgeTitle(e)[item]['seniors']);
    }else if((forWhom == 4 || forWhom == 5) && (patientAgeType !== 'teenager' || patientAgeType !== 'toddler')) {
        titleArr = titleArr.concat(mathAgeTitle(e)[item][patientAgeType]);
    } else if((forWhom == 6 || forWhom == 7) && patientAgeType !== 'seniors') {
        titleArr = titleArr.concat(mathAgeTitle(e)[item][patientAgeType]);
    }else if(forWhom === 'other' && (patientAgeType !== 'adult' || patientAgeType !== 'seniors')) {
        titleArr = titleArr.concat(mathAgeTitle(e)[item][patientAgeType]);
    }
    return titleArr;
}

/**
 * 获取描述逻辑
 * **/
function intellDecFn(e) {
    const { forWhom, age } = e;
    const item = relationArr[forWhom];
    let decArr = randomDec(e)[item];
    let decAgeArr = randomAgeDec(e)[item];
    const patientAgeType = ageType(age);
    /**
     * 2 5 6 父亲丈夫儿子
     * 3 4 7 母亲妻子女儿
     * **/
    if(Number(forWhom) === 1) {
        decArr = decArr.concat(decAgeArr[patientAgeType])
    }
    // 添加小孩描述
    if([6, 7].includes(Number(forWhom)) && patientAgeType === 'toddler') {
        decArr = decArr.concat(decAgeArr[patientAgeType]);
    }

    if([2,5,6].includes(Number(forWhom))) {
        decArr = decArr.filter(res => res.pronoun !== '她')
    }else if([3,4,7].includes(Number(forWhom))){
        decArr = decArr.filter(res => res.pronoun !== '他')
    }

    return decArr;
};
export default {
    intellTitleFn,
    intellDecFn
};
