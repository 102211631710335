import mmcAjax from "@/common/js/mmcAjax"
export default {
    submitProj(projObj) {
        let url = `${window.APIBasePath}mmc/project/create`

        return mmcAjax({
            method: 'post',
            url,
            data: projObj
        })
    },

    getCounselor(cid) {
        let url = `${window.APIBasePath}mmc/project/counselor`

        return mmcAjax({
            method: 'get',
            url,
            showMessage: false
        })
    }
}