const randomAgeDec = res => {
    const { disease, forWhomName, name, age, pronounVal, money, hometownName, spendmoney, hospital = '医院', date } = res;
    const patient_disease = disease; //  '疾病'
    const patient_name = name; // '患者姓名'
    const relationText = forWhomName;
    const pronoun = ['', '他', '她'][pronounVal]; // '汉字性别'
    const project_amount = money; //'筹款金额'
    const address = hometownName; // '家庭住址'
    let hospitalName = '医院'
    if (hospital) {
        hospitalName = hospital
    }

    const cost_amount = spendmoney; //'已经花费'
    const diagnosis_date = date; //'确诊时间'

    const selfTemplates = {
        'toddler': [{
                code: `1`,
                text: `大家好，我叫${patient_name}，家住${address} ${age ? '，今年' + age + '岁' : ''}。听家里人说我得了${patient_disease}，我年龄小，不是很懂这到底意味着什么，只是感觉身体不舒服，很疼很疼。家里为了给我治病，花了很多钱，快要撑不下去了。我还很小，很想能像同龄的小朋友一样，去玩去上幼儿园。现在还需要${project_amount}元治疗费用，希望好人的叔叔阿姨哥哥姐姐们能帮帮我，等我长大后，我也一定会做一个和你们一样善良的人，谢谢你们！` +
                    `\n\n患者病情介绍：\n` +
                    `前段时间，我一直感觉很不舒服，常常哭闹，爸妈就带我去医院检查。结果出来后，他们就没再笑过了，我有些害怕，不知道${patient_disease}到底是什么。很快，我就住院开始了各种治疗，每天要吃很多药，还要打针输液，很疼很疼。爸妈一边哄我，一边哭，我知道她很心疼我，可是我却什么也做不了，只是盼着赶紧好起来。` +
                    `\n\n患者家庭情况：\n` +
                    `我们一家三口生活在一起，本来是很幸福的，爸妈下班后再累也会给我讲故事，每到周末还会特意挤出时间带我出去玩。但生病后，我大部分时间都在医院接受各种治疗。现在，爸爸妈妈每天除了照顾我，还要拼命工作。因为高昂的治疗费已经让我们家一贫如洗了。虽然亲朋好友借了一些钱给我们，${cost_amount ? '现在已经花费了'+cost_amount + '元，' : '' }可离${project_amount}元的治疗费还是差很远。为此，爸爸总在叹气，妈妈也会偷偷的抹眼泪。` +
                    `\n\n求助呼吁：\n` +
                    `希望能有好心人帮帮我，我真的很想恢复健康，重新看到爸爸妈妈的笑脸。同时我也希望以后有机会能用自己的努力来回报父母，也会像每一个帮助过我的人一样，尽力去帮助社会上有困难的人。谢谢你们！好人一生平安！`
            },
            {
                code: `2`,
                text: `大家好，我的名字叫${patient_name}，${age ? '今年' + age + '岁，' : ''}住在${address}。自从我得了${patient_disease}开始，我就没有什么玩儿的时间，也没有什么朋友了。现在，我最常待的地方就是医院。为了给我治病，家里人除了照顾我就是外出挣钱，早出晚归的。但是医生叔叔阿姨说，我的这个情况还需要${project_amount}元费用。希望看到这里的好心人可以帮帮我，真的很感谢你们！` +
                    `\n\n患者病情介绍：\n` +
                    `${diagnosis_date ? diagnosis_date + '的时候' : '最近'}我突然开始身体不舒服，持续了一段时间，家里人赶紧带我到${hospitalName}检查，被确诊为${patient_disease}。这个病我们都不太了解，但是医生叔叔说，是很严重的，需要马上接受治疗。因为我的病，给家里带来了很大的困扰和负担，他们不仅要担心我的病情，还要操心一大笔的治疗费。` +
                    `\n\n患者家庭情况：\n` +
                    `我们家在一个小城市，家里虽然不富裕，但我们过的很幸福很开心。平时，爸爸妈妈都会把最好的给我，他们上班再累也会抽出时间来陪我。自从我生了病，妈妈就没办法工作了，因为要照顾我，爸爸则把全部时间都用在工作上，希望尽可能多的给我挣一些治疗费用。可离${project_amount}元的治疗费还是差很远。为此，爸爸总在叹气。打针很疼的时候，妈妈会心疼的抱着我，安慰我。` +
                    `\n\n求助呼吁：\n` +
                    `我这个病让我不能像其他小朋友一样享受快乐的童年，希望看到这里的好心人能帮帮我，也帮帮我们家。我想好起来，我想和邻居家的小姐姐一样去上学。您的善心就是我活下去的希望，大恩大德，永生难忘！`
            },
        ],
        'teenager': [{
                code: `3`,
                text: `我叫${patient_name}，${age ? '今年' + age + '岁，' : ''}家住${address}，现在还是一名学生。突然有一天，还在学校上课的我突然感觉不舒服，回家和家人一说便马上就去了医院检查。我被查出患有${patient_disease}，听医生说这是一个很严重的疾病，治疗费用需要${project_amount}元，${cost_amount ? '现在已经花费了'+cost_amount + '元，' : '' }所以这个消息对于我家来说犹如晴天霹雳。希望大家能帮帮我，我真的很想重新回到学校，和同学们一起上课，谢谢你们。` +
                    `\n\n患者病情介绍：\n` +
                    `${diagnosis_date ? diagnosis_date + '的时候' : '最近'}我突然昏倒，被送进${hospitalName}治疗，最后被确诊得了${patient_disease}。我虽然不是很清楚这个病到底有多严重，但是我知道这是一个大病，一个可能未来让我的爸爸妈妈承受巨大压力的大病。身体的痛苦加上心里的内疚与自责让我度日如年，我知道为了治病爸爸妈妈已经为我付出了很多，但是费用的昂贵让他们压力很大，我看见过很多次爸爸躲在一边偷偷的抹眼泪，而躺在病床上的我却无能为力。` +
                    `\n\n患者家庭情况：\n` +
                    `我爸爸妈妈都是普通老百姓，家里还有年迈的奶奶需要照顾。日子虽然不富裕，但是却很幸福，我每天都能看见爸爸妈妈的笑容。但自从我生病之后，爸爸妈妈每天愁容满面。我甚至还偷偷听到过他们在楼梯间里商量该向谁去借钱。家里已经花了${cost_amount ? '现在已经花费了'+cost_amount + '元，' : '很多钱' }，现在我这一病又需要花${project_amount}元治疗费，我的爸爸妈妈该怎么办呀？` +
                    `\n\n求助呼吁：\n` +
                    `希望大家都能救救我，帮帮我的父母，帮帮我们这一家，让我们度过难关！你们一点一滴的帮助，我们都会铭记在心，将来在我长大之后，一定回馈社会，将这份爱心传递出去。在此先谢谢每一个愿意帮助我的好心人，愿你们一生幸福平安！`
            },
            {
                code: `4`,
                text: `大家好，我的名字叫${patient_name}，${age ? '今年' + age + '岁，' : ''}家住${address}。我家是一个不富裕的农村家庭，平日里爸妈省吃俭用，只为了能把最好的给我，让我能和其他孩子一样正常上学。虽然不富裕，但是很幸福，可自从${diagnosis_date ? diagnosis_date : ''}我得了${patient_disease}，就再也没看见过父母的笑脸了，因为我们家掏空家底只能凑到${cost_amount ? cost_amount + '元，' : '很少' }的治疗费，离${project_amount}元的治疗费差得很远。这让全家都笼罩在乌云里。希望有心人能帮帮我，我还年轻，还有梦想没有完成。谢谢你们！` +
                    `\n\n患者病情介绍：\n` +
                    `${diagnosis_date ? diagnosis_date + '的时候' : '最近'}正在学校上课的我突然感觉身体非常难受。回家之后妈妈让我吃了退烧药，并且安慰我很快就好了，没想到这突如其来的高烧持续了好几天也不肯退却，实在没办法了，妈妈带我去了${hospitalName}。听医生说我得了${patient_disease}，让我立刻住院，同时要我爸爸妈妈做好准备，至少要准备${project_amount}元的医疗费，我看见妈妈愣了几秒随后就眼泪不止，爸爸在旁边也默默红了眼眶。这是我第一次住院，虽然有父母陪同，但我依然觉得害怕不已。妈妈经常安慰我：“孩子别怕，咱们一定能好的！”看着妈妈这样，我也想赶紧好起来，以后好好报答父母的恩情。` +
                    `\n\n患者家庭情况：\n` +
                    `我家是一个不富裕的农村家庭，平日里爸妈省吃俭用，只为了能把最好的给我，让我能和其他孩子一样正常上学。我知道爸妈的良苦用心，于是我努力学习，争取早日出头，能让爸妈过上更好的生活。天不遂人愿，命运却让我得了如此严重的疾病，任凭泪流满面都好像毫无转机。医生说要治好我这个病需要${project_amount}元，${cost_amount ? '但我们家想尽所有办法都只能筹到'+cost_amount + '元，' : '' }这笔巨额的医疗费对于我家来说简直是晴天霹雳，我们该去哪弄这笔钱啊！` +
                    `\n\n求助呼吁：\n` +
                    `我不想死，更不想就这样离开我的父母！我还有 很多梦想没有实现，还有很多美好的未来正等着我！我更想好好的报答我父母对我的养育之恩。希望好心的叔叔阿哥哥姐姐来帮帮我，帮助我完成梦想，我一定将恩情铭记心中，以后常做善事来回报社会！大恩大德，我必定永记于心！`
            },
        ],
        'adult': [{
            code: `5`,
            text: `点开这篇筹款的好心人，我叫${patient_name}，${age ? '今年'+ age + '岁，' : '' }家住${address}，虽然工资不高，但平时生活得很幸福。我有孩子需要抚养，家中还有老人需要赡养，明明是家里的${pronoun=="他" ? '顶梁柱' : '贤内助'} ，却不幸患上${patient_disease}，对家人我有着深深的愧疚。面临巨额的${project_amount}元的治疗费，我实在没有其他办法了。现在求助宠物筹平台上的好心人，能够帮帮我，谢谢你们！` +
                `\n\n患者病情介绍：\n` +
                `${diagnosis_date ? diagnosis_date + '的时候，' : '最近，'}我突然感觉身体疼痛难忍，于是去了${hospitalName}检查。医生觉得比较严重，于是建议我们去大地方的医院做进一步的检查。经过一系列检查后，我最终还是被确诊为${patient_disease}。从确诊到现在，我已经用光了家庭所有的积蓄，但是医生说我必须住院，现在面对巨额的${project_amount}元的医疗费压力，我真的无心康复身体。` +
                `\n\n患者家庭情况：\n` +
                `听到这个消息时，全家人犹如晴天霹雳。我平时在外地打工花销大，省吃俭用过年才敢回家看看，这些年没存下多少钱。可是医生却说治疗我的病，保守估计至少需要${project_amount}元。我们已经借遍了所有的亲朋好友，实在没有别的办法了。` +
                `\n\n求助呼吁：\n` +
                `希望各位爱心朋友能帮帮我们一家，我们能想的办法都想尽了，可是手术费还差${project_amount}元。我还想继续成为全家人的依靠，求求所有的好心人帮帮我吧！`
        }, {
            code: `6`,
            text: `各位朋友大家好，我的名字叫${patient_name}，${age ? '今年'+ age + '岁，' : '' }${address}人。我平时收入虽然不高，但是相对稳定，日子虽然不富裕，倒也安稳快乐。只是没想到${diagnosis_date ? diagnosis_date : '最近'}厄运突然降临在我头上，我被确诊为${patient_disease}。这种病需要的手术费、治疗费和后期康复费用需要${project_amount}元，对我来说不吃不喝工作好多年才能赚到，求助爱心人士，能帮帮我，好人一生平安！` +
                `\n\n患者病情介绍：\n` +
                `其实，我早就感觉到了身体不舒服，直到扛不住了才来医院看病，却没想到这一查就是${patient_disease}！当天，医生便让我立刻住院。这个消息犹如晴天霹雳，全家都不敢相信这是事实。但比起心理上的痛苦，高昂的医药费也是压在全家心头的一座大山，截止到现在，${cost_amount ? '已经花费了'+cost_amount + '元，' : '' }实际还需要医疗费${project_amount}元，家人安慰我说，砸锅卖铁都要给我治好！` +
                `\n\n患者家庭情况：\n` +
                `人们都说，家和万事兴，我本来有一个快乐的家庭，可是没想到老天爷不眷顾我们，让我得了这个病。现在，我们全家已经拿不出${project_amount}元的医疗费再为我治病了，可是看到爸妈和孩子期盼的脸，不想让父母白发人送黑发人，我还想再活下去！` +
                `\n\n求助呼吁：\n` +
                `所以，看到这条筹款的好心人呐，我求求您救救我。给我和我的全家一次再生的希望吧！哪怕只有几块钱，对我来说也是生还的曙光，您就是我们全家的救命恩人。 `
        }, ],
        'seniors': [{
            code: `7`,
            text: `我是${patient_name}，${age ? '今年'+ age + '岁，' : '' }${address}人，是个老实本分的劳动者。我们一家日子虽然清贫，但是孩子孝顺懂事，妻子贤惠，丈夫顾家，也是个幸福的三口之家。但是天有不测风云，就在${diagnosis_date ? diagnosis_date + '的时候' : '最近，'}一场灾难摧毁了我们幸福的一家。我患上了${patient_disease}，这场病带来的不止是痛苦，还有犹如天文数字的${project_amount}元治疗费用的压力。希望看到这里的好心人，能帮帮我，救救我们这一家人，谢谢你们。` +
                `\n\n患者病情介绍：\n` +
                `一开始我就感觉自己不舒服，但是没在意，去小诊所开了药。后来怎么吃药也没见好，情况反而越来越严重，赶紧去了${hospitalName}。到医院后经过一系列检查后，我被确诊为${patient_disease}。必须尽快住院，这简直就是晴天霹雳。医生还说这个病还需要${project_amount}元治疗费，${cost_amount ? '顶目前已经花费了' + cost_amount + '元，' : ''}如果出现了其他问题，就不好说还需要多少钱了。` +
                `\n\n患者家庭情况：\n` +
                `这笔费用对我来说简直是天文数字。我们一家都靠我在外打工赚的钱生活，平时只能勉强糊口，几乎没有积蓄。家里的孩子还在上学，老人也需要人照顾。现在把亲戚朋友能借的都借了，${cost_amount ? '凑出了第一次手术费'+cost_amount + '元，' : '' }后面的${project_amount}元的治疗费实在是凑不出来也借不到了，我真的不知道该怎么办了。` +
                `\n\n求助呼吁：\n` +
                `无奈下，我在宠物筹平台上发起求助，希望好心人能帮帮我，帮帮我这苦难的家庭。一两毛、一块两块的爱心只要聚集起来，就能给我这个苦难的家庭带来一份希望！好人一生平安，希望人人献出一点爱，人间可以是春天！在此，我先谢谢各位善良的好心人了！`
        }, {
            code: `8`,
            text: `我叫${patient_name}，${age ? '今年'+ age + '岁，' : '' }来自${address}。我本有个幸福的小家庭，但是天有不测风云，${diagnosis_date ? '就在'+diagnosis_date : '最近，'}我被查出患有${patient_disease}。疾病不仅摧毁了我，也摧毁了这个家。我是家里的顶梁柱，现在我倒了，还留下了${project_amount}元治疗费的缺口，这让我这个破败的家雪上加霜。都说众人拾柴火焰高，希望好心人能够帮帮我，我必铭记于心。` +
                `\n\n患者病情介绍：\n` +
                `${diagnosis_date ? diagnosis_date + '的时候' : '最近，'}我觉得头有一点疼，但是没在意，去小诊所开了药。后来情况越来越严重，一直发烧呕吐，直到疼到昏厥了才赶紧去了${hospitalName}。到医院后经过一系列检查，我被确诊为${patient_disease}。医生说这个病还需要${project_amount}元治疗费。因为没钱治疗，我只能选择先不手术，接受保守治疗${cost_amount ? '，但是即使这样也已经花费了' + cost_amount + '元' : ''}。而且看着自己的身体每况愈下，家里经济越来越困难，父母妻子和孩子每天以泪洗面，我心里真不是个滋味。有时候疼痛让我想到“不如就这么死了吧”，但是看到孩子和这个家，我又想赶紧治病活下去。` +
                `\n\n患者家庭情况：\n` +
                `治疗费对我来说简直是天文数字。平时我们这个小家庭都靠我在外打工赚的钱生活，平时只能勉强糊口。家里的孩子还在上学，老人身体不好需要一直服药，瘫痪在床没有自理能力需要人照顾。现在妻子把亲戚朋友能借的都借了，后面的治疗费${project_amount}元实在是凑不出来，也借不到了，请大家帮帮我。` +
                `\n\n求助呼吁：\n` +
                `宠物筹的工作人员在医院了解到我的情况后主动询问我是否需要帮助，于是我选择发起求助，希望好心人能帮我渡过难关。一两毛、一块两块的爱心只要聚集起来，就能给我带来生命的希望，更能拯救我的家！好人一生平安，希望人人献出一点爱，人间可以是春天！在此，先谢谢各位善良的好心人了！`
        }, ]

    };
    const childTemplates = {
        'toddler': [{
                code: `48`,
                pronoun: `她`,
                text: `听着宝宝的哭声，我心急如焚！！ 宝宝是一家的希望，谁都想看着健康快乐长大，可是却被确诊${patient_disease}需要治疗，家里真的承担不起了，无奈我求助社会。` +
                    `\n\n患者病情介绍：\n` +
                    `患者是我的女儿，我们家住${address}，一个普通家庭，女儿出生后，我对未来生活也更加期待。 可天却不遂人愿，今年，我们偶然发现女儿身体不舒服，我们才带女儿到医院。 谁想，医生的话让我一下瘫坐在地上！医生说女儿是${patient_disease}，我听完医生的话几近崩溃，感觉家里天都塌了！！怎么会得这个天大的病！！ 我不敢相信这个结果，抱着一丝希望，经过一系列检查，医生告诉我，孩子确诊${patient_disease}就这样厄运降临到了我们的家！！\n看着诊断书的白纸黑字，我脑子一片空白，我知道怨天尤人解决不了任何问题，我一定要救女儿，在医院，我们积极配合医生治疗，女儿身躯承受着成人都难以忍受的磨难！！要是可以，我多想替她承受这些痛苦！ 我们就是普通家庭，这短短时间，可这才刚刚开始啊！因为孩子病情太重，多亏了大家的帮助，我们才坚持给孩子治疗，可面对后期的治疗，我真的压力好大。` +
                    `\n\n求助呼吁：\n` +
                    `万般无奈，我想到通过宠物筹筹平台求助大家，希望大家看在天下父母救子心切的份上，帮帮我，让孩子坚持治疗下去，谢谢好心人！`
            },
            {
                code: `55`,
                pronoun: `她`,
                text: `我们家是一个普通家庭,可是天有不测风云，女儿${patient_name}，结果确诊为${patient_disease},听到这个消息我感觉天都塌了，根本就无法接受这个事实，住院期间主治医生告诉我们现在可以治疗。这也算不幸中的好消息了吧。` +
                    `\n\n患者病情介绍：\n` +
                    `做为父母我们就算砸锅卖铁，也要治我的孩子，就开始一系列的检查，她还这么小就受这么多痛苦。我也从未有过的进退两难的决定，这次挣扎的好久，我们只有两条路选择，而哪一条路都很心痛，孩子这么小就让她跟死神搏斗，去受常人无法承受的痛苦，而在门外的我，心总是提着，不敢走开半步，怕她受不了，每一次治疗都会眼睛周围面部冲满了血，每次进去抱她的时候，她都是奄奄一息，医生说，没办法，治疗还是要接着完成，不过这里也有一定的风险。\n我们没有选择，为了治好她我们没有选择，能帮助的亲戚朋友都已经帮助过了，有的亲戚朋友他们都劝我们放弃，这个病它是个无底洞，你们以后的生活也要过，你们不能全部搭在她一个人身上，你们俩人又没人帮，全靠自己，她们说的这些，我们怎么能不知道，我们也怕人才两空，可她是我身上的一块肉，我真的没办法看着我的孩子，现在还好好活蹦乱跳的在我面前，我们把她抱回去一天一个变化的在我们面前，怎么能受了，放弃二字我真的说不出口。\n只要医生还说我的孩子有希望，我们一定要坚持下去，因为她刚刚来到这个世界，她的时间还很长，很长。我的宝宝，爸爸妈妈怎么可能舍得你呢。 ` +
                    `\n\n患者家庭情况：\n` +
                    `经过这几个多月的治疗费用，已经用光了我们家所有的存款。我们现在该借的都已经借的，实在是拿不出来，我们真不知道该怎么办了，望社会上好心人帮帮我这可怜的孩子。` +
                    `\n\n求助呼吁：\n` +
                    `恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
            },
            {
                code: `60`,
                pronoun: `他`,
                text: `听着宝宝的哭声，我心急如焚！！ 宝宝是一家的希望，谁都想看着健康快乐长大，可是却被确诊${patient_disease}需要治疗，家里真的承担不起了，无奈我求助社会。` +
                    `\n\n患者病情介绍：\n` +
                    `患者是我的儿子，我们家住${address}，一个普通家庭，儿子出生后，我对未来生活也更加期待。 可天却不遂人愿，今年，我们偶然发现儿子面色不好，我们才带儿子到县里的医院。 谁想，医生的话让我一下瘫坐在地上！医生说儿子是${patient_disease}，我听完医生的话几近崩溃，感觉家里天都塌了！！怎么会得这个天大的病！！ 我不敢相信这个结果，抱着一丝希望，经过一系列检查，医生告诉我，孩子确诊${patient_disease}就这样厄运降临到了我们的家！！\n看着诊断书的白纸黑字，我脑子一片空白，我知道怨天尤人解决不了任何问题，我一定要救儿子，在医院，我们积极配合医生治疗，儿子身躯承受着成人都难以忍受的磨难！！要是可以，我多想替他承受这些痛苦！` +
                    `\n\n患者家庭情况：\n` +
                    `我们就是普通家庭，这短短时间，可这才刚刚开始啊！医生说因为孩子病情太重，多亏了大家的帮助，我们才坚持给孩子治疗，可面对后期的治疗，我真的压力好大，万般无奈，我想到通宠物筹平台求助大家，希望大家看在天下父母救子心切的份上，帮帮我，让孩子坚持治疗下去，谢谢好心人！	` +
                    `\n\n求助呼吁：\n` +
                    `恳请各位好心人士伸手相助，多多转发，多多证实。您的每一次转发、证实我们都至关重要，每一次转发对我们来说都是莫大的帮助!恳请各位好心人士伸手相助，多多转发，万分感谢大家！`
            },
            {
                code: `64`,
                pronoun: `他`,
                text: `在这个世界上，让人无法预知的是疾病和意外，有些人，一别或许就是一辈子！我的孩子叫${patient_name}，让病魔缠身，此刻正躺在病床上${age ? '，虽然他才' + age + '岁，' : ''}，幼小的他却要承受着，我们常人或许一辈子都不曾经历过的痛苦！在这里首先向社会各界爱心人士深鞠一个躬。` +
                    `\n\n患者病情介绍：\n` +
                    `大家好！我们是${address}人。我的儿子在原本天真烂漫的年纪被确诊为${patient_disease}，别人去大城市都是旅游，是奋斗，是创业，而我们只是求生、求活命。 时至今日，幼小的儿子承受的太多太多，可孩子懂事的让人心绞痛，当他听到主任医生说得病的时候，他第一时间安慰的是妈妈。` +
                    `\n\n患者家庭情况：\n` +
                    `回想起儿子在一次次治疗过程中受到的痛苦和折磨，我恨不得自己能够去替代他。从被确${patient_disease}的那天起，我们这个普通家庭早已负债累累，但为了儿子以积极的心态战胜病魔，只要儿子康复我们再苦再累都是值得的。\n现在面对未来的治疗费用，真是老账未去，新账又来，现在的治疗的费用真的让我们这个家庭实属无力，后期的费用真的是一个可怕的天文数字，我们真的走投无路被迫发起这次求助。	` +
                    `\n\n求助呼吁：\n` +
                    `恳求社会各界爱心人士看见的，麻烦您动用下、健康发财的小手帮忙转发出去，我们急需您的帮助！多一份转发就多一份希望，接力点亮生命的希望之光。`
            },
            {
                code: `65`,
                pronoun: `他`,
                text: `我们是一个普通家庭,可是天有不测风云，儿子${patient_name}，结果确诊为${patient_disease},听到这个消息我感觉天都塌了，根本就无法接受这个事实。` +
                    `\n\n患者病情介绍：\n` +
                    `儿子住院期间主治医生告诉我们现在可以治疗。这也算不幸中的好消息了吧。做为父母我们就算砸锅卖铁，也要治我的孩子，就开始一系列的检查，他还这么小就受这么多痛苦。我也从未有过的进退两难的决定，这次挣扎的好久，我们只有两条路选择，而哪一条路都很心痛，孩子这么小就让他跟死神搏斗，去受常人无法承认的痛苦，而在门外的我，心总是提着，不敢走开半步，怕他受不了，做一次都会眼睛周围面部冲满了血，医生说，没办法，治疗还是要接着完成，不过这里也有一定的风险。\n我们没有选择，为了治好他我们没有选择，能帮助的亲戚朋友都已经帮助过了，有的亲戚朋友他们都劝我们放弃，这个病它是个无底洞，你们以后的生活也要过，你们不能全部搭在他一个人身上，你们俩人又没人帮，全靠自己，她们说的这些，我们怎么能不知道，可他是我身上的一块肉，我真的没办法看着我的孩子，现在还好好活蹦乱跳的在我面前，我们把他抱回去一天一个变化的在我们面前，怎么能受了，放弃二字我真的说不出口。\n只要医生还说我的孩子有希望，我们一定要坚持下去，因为他还刚刚来到这个世界，他的时间还很长，我的宝宝，爸爸妈妈怎么可能舍得你呢。` +
                    `\n\n患者家庭情况：\n` +
                    `经过这几个多月的治疗费用，已经用光了我们家所有的存款。我们现在该借的都已经借了，实在是拿不出来，我们真不知道该怎么办了，望社会上好心人帮帮我这可怜的孩子。` +
                    `\n\n求助呼吁：\n` +
                    `恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
            },
        ]
    };
    const parentTemplates = {};
    const coupleTemplates = {};
    const otherTemplates = {};

    const templateAgeData = {
        self: selfTemplates,
        parent: parentTemplates,
        child: childTemplates,
        couple: coupleTemplates,
        other: otherTemplates,
    }
    return templateAgeData
}

export default randomAgeDec;