<template>
    <van-popup class="autoPopBox" round position="bottom" v-model="popShow" >
        <div class="title">
            <i class="close" @click="close"></i>
            <h3>完善患者信息，智能生成</h3>
            <p class="dec">填的越多，内容越丰富</p>
        </div>

        <ai-form class="form-box" ref="formItems" :model="formValidate" :rules="ruleValidate">
            <ai-form-item label="目标金额" prop="money">
                <ai-input placeholder="请输入目标金额" type="number" v-model="formValidate.money"/><i class="text">元</i>
            </ai-form-item>
            <ai-form-item label="为谁筹款" prop="forWhom">
                <span :class="formValidate.forWhom ? 'select':'unselect'" @click="showForwhoBox">{{ formValidate.forWhomName || '请选择'}}</span>
            </ai-form-item>
            <ai-form-item label="患者姓名" prop="name">
                <ai-input  placeholder="请填写患者姓名" v-model="formValidate.name"/>
            </ai-form-item>
            <ai-form-item v-if="showSexBox" label="患者性别" prop="pronounVal">
                <span :class="formValidate.pronounVal ? 'select':'unselect'" @click="showPronBox">{{ formValidate.pronoun || '请选择'}}</span>
            </ai-form-item>
            <ai-form-item label="患者年龄" prop="age">
                <ai-input  placeholder="请填写患者年龄" type="number" v-model="formValidate.age"/>
            </ai-form-item>
            <ai-form-item label="疾病名称" prop="disease">
                <ai-input  placeholder="请填写疾病名称" v-model="formValidate.disease"/>
            </ai-form-item>
            <ai-form-item label="患者家乡" prop="hometown">
                <span :class="formValidate.hometown ? 'select':'unselect'" @click="showAreaBox">{{ formValidate.hometownName || '请选择'}}</span>
            </ai-form-item>
            <div style="height: 8px;background: #F5F6F7"></div>
            <div class="sub-dec">
                <h3 class="select-info">以下信息选填</h3>
                <p class="select-dec">填写越多筹款效果越好</p>
                <ai-form-item label="确诊日期" prop="date">
                    <span :class="formValidate.date ? 'select':'unselect'" @click="showTimeBox">{{ formValidate.date || '请选择'}}</span>
                </ai-form-item>
                <ai-form-item label="就诊医院" prop="hospital">
                    <ai-input  placeholder="就诊医院简单填写即可" v-model="formValidate.hospital"/>
                </ai-form-item>
                <ai-form-item label="已花费金额" prop="spendmoney">
                    <ai-input  placeholder="请填写已花费金额" v-model="formValidate.spendmoney"/> <i class="text">元</i>
                </ai-form-item>
            </div>
            <button class="submit-btn" @click="changeButton">提交</button>
        </ai-form>
        <!--  选择患者关系  -->
        <van-popup round :close-on-click-overlay="false" class="forwho" position="bottom" v-model="forwhoBox" >
            <van-picker
                title="请选择与受助人的关系"
                show-toolbar
                :default-index="formValidate.forWhom - 1"
                :columns="action"
                @confirm="onConfirm"
                @cancel="onCancel"
                @change="onChange"
            />
        </van-popup>
        <!--  选择性别  -->
        <van-popup round :close-on-click-overlay="false" class="forwho" position="bottom" v-model="pronBox" >
            <van-picker
                title="请选择患者性别"
                show-toolbar
                :default-index="formValidate.pronounVal - 1"
                :columns="pronBoxAction"
                @confirm="onSexConfirm"
                @cancel="onSexCancel"
            />
        </van-popup>
        <!--  选择家乡  -->
        <van-popup round :close-on-click-overlay="false" class="forwho" position="bottom" v-model="areaBox" >
            <van-area
                title="选择家乡"
                :value="formValidate.hometown"
                :area-list="areaList"
                @confirm="areaConfirm"
                @cancel="areaCancel"
            />
        </van-popup>
        <!--  选择确诊日期  -->
        <van-popup round :close-on-click-overlay="false" class="forwho" position="bottom" v-model="timeBox" >
            <van-date
                :value="formValidate.dateVal || new Date()"
                type="date"
                title="选择年月日"
                :formatter="formatter"
                :maxDate="maxDate"
                @confirm="timeConfirm"
                @cancel="timeCancel"
            />
        </van-popup>
    </van-popup>
</template>

<script>
import { Picker, Popup, Area, DatetimePicker } from 'vant';
import AiForm from "./form";
import AiFormItem from "./form-item";
import AiInput from "./input";
import { areaList } from '@vant/area-data';
import util from '../../../../common/js/utils'
import { validAge, validName, validMoney, validSpendMoney } from '../js/rules'
export default {
    name: 'home',
    props: {
        IntellForm: {
            type: Object,
            default: '',
        },
    },
    components: {
        AiInput,
        AiFormItem,
        AiForm,
        vanPicker: Picker,
        vanPopup: Popup,
        vanArea: Area,
        vanDate: DatetimePicker,
    },
    data() {
        return{
            showSexBox: false,
            pronBox: false,
            maxDate: new Date(),
            areaList,
            forwhoBox: false,
            timeBox: false,
            areaBox: false,
            popShow: true,
            pronBoxAction: [
                { text: '男', value: '1' },
                { text: '女', value: '2' },
            ],
            action: [
                { text: '本人', value: '1' },
                { text: '父亲', value: '2' },
                { text: '母亲', value: '3' },
                { text: '妻子', value: '4' },
                { text: '丈夫', value: '5' },
                { text: '儿子', value: '6' },
                { text: '女儿', value: '7' },
                { text: '其他', value: '8' }
            ],
            formValidate: {
                name: '',
                forWhom: '',
                forWhomName: '',
                money: '',
                age: '',
                pronoun: '',
                pronounVal: '',
                disease: '',
                hometown: '',
                hometownName: '',
                date: '',
                dateVal: '',
                hospital: '',
                spendmoney: '',
            },
            ruleValidate: {
                money: [
                    { required: true, trigger: 'blur', message: validMoney(),  validator: (rule, val) => {
                            if(val === '') return false
                            if(val < 1 || val > 9999999) {
                                return false
                            }
                            return true
                        }},
                ],
                name: [
                    { required: true, message: validName(), trigger: 'blur', validator: (rule, val) => {
                            if(val === '') return false
                            if(val.length < 2 || val.length > 10) {
                                return false
                            }
                            return true
                        } },
                ],
                forWhom: [
                    { required: true, message: '请选择为谁筹款', trigger: 'blur' },
                ],
                pronounVal: [
                    { required: true, message: '请选择患者性别', trigger: 'blur' },
                ],
                age: [
                    { required: true, message: validAge(), trigger: 'blur', validator: (rule, val) => {
                            if(!val) return false
                            if(Number(val) >= 0 && Number(val) <= 999) {
                                return true
                            }
                            return false
                        }
                    },
                ],
                disease: [
                    { required: true, message: '请填写正确的疾病名称', trigger: 'blur' },
                ],
                hometown: [
                    { required: true, message: '请选择患者家乡', trigger: 'blur' },
                ],
                date: [
                    { required: false, message: '用户名不能为空', trigger: 'blur' },
                ],
                hospital: [
                    { required: false, message: '用户名不能为空', trigger: 'blur' },
                ],
                spendmoney: [
                    { required: false, message: validSpendMoney(), trigger: 'blur', validator: (rule, val) => {
                            if(val === '') return true
                            if(val < 1 || val > 999999) {
                                return false
                            }
                            return true
                        } },
                ],
            }
        }
    },
    mounted() {
        this.formValidate = this.IntellForm;
        if(this.formValidate.forWhom == 1 || this.formValidate.forWhom == 8) {
            this.showSexBox = true
        }
    },
    methods:{
        formatter(type, val) {
            if (type === 'year') {
                return val + '年';
            }
            if (type === 'month') {
                return val + '月';
            }
            if (type === 'day') {
                return val + '日';
            }
            return val;
        },
        timeConfirm(e) {
            this.formValidate.date = util.formatDate(e,'yyyy年MM月dd日')
            this.formValidate.dateVal = new Date(e)
            this.timeBox = false;
        },
        timeCancel() {
            this.timeBox = false;
        },
        areaConfirm(e) {
            this.formValidate.hometown = e[2].code;
            if(e[0].code === "110000") {
                this.formValidate.hometownName = e[0].name + e[2].name;
            }else{
                this.formValidate.hometownName = e[0].name + e[1].name + e[2].name;
            }

            this.areaBox = false;

            this.$refs.formItems.validateItem(5)
        },
        areaCancel() {
            this.areaBox = false;
        },
        onConfirm(e) {

            this.formValidate.forWhomName = e.text;
            this.formValidate.forWhom = e.value;
            if(e.value == 2 || e.value == 5 || e.value == 6) {
                this.formValidate.pronoun = '男';
                this.formValidate.pronounVal = '1';
                this.showSexBox = false;
            }else if(e.value == 3 || e.value == 4 || e.value == 7){
                this.formValidate.pronoun = '女';
                this.formValidate.pronounVal = '2';
                this.showSexBox = false;
            }else{
                this.showSexBox = true;
            }
            this.forwhoBox = false;
            this.$refs.formItems.validateItem(1);
        },
        onCancel() {
            this.forwhoBox = false;
        },
        onSexConfirm(e) {
            this.formValidate.pronoun = e.text;
            this.formValidate.pronounVal = e.value;
            this.pronBox = false
            this.$refs.formItems.validateItem(9);
        },
        onSexCancel() {
            this.pronBox = false
        },
        onChange() {

        },
        showTimeBox() {
            this.timeBox = true;
        },
        showAreaBox() {
            this.areaBox = true;
        },
        showForwhoBox() {
            this.forwhoBox = true;
        },
        showPronBox() {
            this.pronBox = true;
        },
        changeButton(e) {
            e.preventDefault();
            // this.$refs.formItems.resetFields() // 清空
            this.$refs.formItems.validate().then(res=>{
                if(res){
                    this.$emit('submit', this.formValidate)
                }
            }).catch(err => {
                console.log('err')
                console.log(err)
            })
        },
        close() {
            this.$emit('close');
        }
    },
}
</script>
<style scoped lang="less">
.autoPopBox {
    width: 100%;
    height: 90vh;
    padding: 90px 0 0;
    overflow: hidden;
    .title {
        position: absolute;
        width: 100%;
        top: 0;
        .close {
            position: absolute;
            right: 15px;
            top: 20px;
            width: 22px;
            height: 22px;
            background: url('../img/close.png');
            background-size: 15px 17px;
            background-repeat: no-repeat;
        }
        h3 {
            font-size: 21px;
            line-height: 1;
            margin: 0 0 13px;
            text-align: center;
            padding-top: 22px;
        }
        .dec {
            font-size: 13px;
            color: #999;
            text-align: center;
            padding-bottom: 16px;
        }
    }
    .form-box {
        height: calc(90vh - 90px);
        overflow: scroll;
        padding-bottom: 90px;
        /deep/ .input-box {
            .text {
                font-style: normal;
                font-size: 16px;
                margin-left: 3px;
            }
        }
        .select,.unselect {
            position: relative;
            font-size: 16px;
            padding-right: 14px;
            min-width: 100px;
            text-align: right;
            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                right: 0;
                top: 6px;
                width: 8px;
                height: 8px;
                border-bottom: 2px solid #aaa;
                border-right: 2px solid #aaa;
                transform: rotate(-45deg);
            }
        }
        .unselect {
            color: #ccc;
        }
    }
    .sub-dec {
        /deep/label {
            font-size: 14px;
            color: #666;
        }
    }
    .select-info {
        text-align: left;
        margin-top: 20px;
        font-size: 14px;
        color: #666;
        padding: 0 10px;
    }
    .select-dec {
        text-align: left;
        margin-top: 5px;
        font-size: 12px;
        color: #ccc;
        padding: 0 10px;
    }
    /deep/.van-picker__confirm {
        color: #0aa0ff;
    }
    .submit-btn {
        width: 92%;
        background: #0AA0FF;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        border: none;
        border-radius: 50px;
        font-weight: bold;
        position: fixed;
        bottom: 32px;
        left: 50%;
        transform: translateX(-50%);
    }
    .forwho {
        height: 300px;
    }
}
</style>

