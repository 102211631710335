const randomDec = res => {
    const { disease, forWhomName, name, age, pronounVal, money, hometownName, spendmoney, hospital = '医院', date } = res;
    const patient_disease = disease; //  '疾病'
    const patient_name = name; // '患者姓名'
    const relationText = forWhomName;
    const pronoun = ['', '他', '她'][pronounVal]; // '汉字性别'
    const project_amount = money; //'筹款金额'
    const address = hometownName; // '家庭住址'
    let hospitalName = '医院'
    if (hospital) {
        hospitalName = hospital
    }
    const cost_amount = spendmoney; //'已经花费'
    const diagnosis_date = date; //'确诊时间'


    const selfTemplates = [{
            code: `28`,
            text: `各位好心人，你们好，我叫${patient_name}，${age ? '今年' + age + '岁，' : ''}${address}人，刚被医生确诊为${patient_disease}，这一下压垮了我和我的家庭，本来幸福的家庭笼罩上了阴云，原来的欢歌笑语都不见了。多希望能有好心人救救我，让我可以活下去！谢谢大家了！` +
                `\n\n患者病情介绍：\n` +
                `${diagnosis_date ? diagnosis_date + '，' : '最近，'}我刚被医生确诊为${patient_disease}，这个疾病来得毫无预兆，拿到结果的时候我愣了好久，好多事情一下全部涌进脑子，不能继续正常生活，不能好好照顾⽗母，不能继续学习工作。确诊的第二天，爸爸就像苍老了二十岁，妈妈满⾯愁容却也打着精神笑着安慰我没事，让我好好做治疗，看着他们因我受苦，我就禁不住的心酸。` +
                `\n\n患者家庭情况：\n` +
                `${cost_amount ? '从确诊到现在已经花费了'+cost_amount + '元，' : ''}医生告诉家里，后续的治疗还需要很⼤一笔钱，家⾥为我四处奔波，亲戚朋友也都尽力为我筹钱，到现在还有${project_amount}元的缺口，我们实在不知道该怎么办了，走投⽆路才向大家求助。` +
                `\n\n求助呼吁：\n` +
                `希望各位可以帮帮我，我还想好好生活，我的未来还有很多无限的可能，我还想继续回报我的⽗母、回报社会，我想用全⼒和病魔斗争，用全⼒热爱⽣活。感谢每⼀位善良的人，哪怕只是转发和留言，对我都是莫大的支持与帮助。谢谢每一位伸出援手的好心人，愿善念永存，愿爱心传递!`
        }, {
            code: `29`,
            text: `各位好心人大家好! 我叫${patient_name}，${age ? '今年' + age + '岁，' : ''}来自${address}，生活过得平凡却很快乐，可是最近，恶魔改变了我的⼈⽣轨迹，我的⽣活失去了色彩和缤纷的世界，我被确诊为${patient_disease}，从此围绕我的只有冰冷的病房。可是我还想活， 求求好心人救救我！` +
                `\n\n患者病情介绍：\n` +
                `我生病了，医生说是${patient_disease}需要花费很多钱才能治好，${cost_amount ? '给我看病已经花去了'+cost_amount + '元，' : ''}还需要${project_amount}元，我的脸色一天天地苍白，疾病的噩耗让我的精神几乎崩溃，我们是真的没有办法了。我还有好多的事要做，我的人生不应该就这样匆匆结束了。` +
                `\n\n患者家庭情况：\n` +
                `高额的费用让原本不太富裕的家庭陷⼊了绝境，${project_amount}元这对我们家来说真的是一笔巨款呀，家庭的经济实力已难以支撑下去。家人愁得天天饭也吃不好，他们经常偷偷抹眼泪。我看着心里也很难过，恨自己得了这个病。` +
                `\n\n求助呼吁：\n` +
                `希望好心人帮帮我，让我可以好好活下去，让我可以告别病房，回到那个美好的世界。谢谢你们。今后我有了能⼒，一定将这份⽆私的爱回馈社会。感谢大家!`
        },
        {
            code: `32`,
            text: `当我拿着手上“沉重”的诊断证明，看着上面沉甸甸的的检查结果，从意外发生以来我一直在和疼痛作斗争，今天万般无奈之下，写下这封求助信，真的是到了万不得已的情况了，希望大家能够帮帮我！` +
                `\n\n患者病情介绍：\n` +
                `我叫${patient_name}${age ? '，今年'+age + '岁' : ''}，家住${address}，最近身体不舒服，感觉有点严重，于是我就到${hospitalName}检查，医生说我的检查结果为${patient_disease}，听到这个结果我很意外，家里拿不出这么多钱，只能在此向大家求助！` +
                `\n\n求助呼吁：\n` +
                `这一次通过宠物筹的爱心平台，向各位有爱的朋友们发起求助，实属无奈之举，在这一路的历程中，我也感受到了很多很多点点滴滴的感动，我明白生活的考验有时是残酷的，当我们自己，我们身边的人遇到困难，我们会始终相互陪伴，相互鼓励，共同去面对我们眼前的一切，每一分的善款，我都会用心的投入到与病痛的斗争中，就为我们彼此的未来，彼此的幸福。\n祝福所有有爱的好心人们，好人一生平安！幸福美满每一天！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助！我承诺筹集的治疗费都是用于治病，如果有用不完善款，我们愿意把剩余的款项退还给赠款人。 希望您能帮忙转发扩散这条求助信息，让爱心传递到每个角落。\n在这里，深深感谢每一位帮助过我们的亲朋好友，感谢每一位无私转发的爱心人士。授人玫瑰，手有余香;奉献爱心，收获希望。我们急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },
        {
            code: `33`,
            text: `尊敬的宠物筹爱心人士，您们好！没有人知道幸福和意外谁先到来，也没有人知道命运会如何安排，很多时候，我们活在的今天，是多少人渴望的明天！` +
                `\n\n患者病情介绍：\n` +
                `我叫${patient_name}${age ? '，今年'+age + '岁' : ''}，家住${address}，是个普普通通的人，虽说日子不富裕，却也是其乐融融，每天都有苦中作乐的幸福，都有对美好生活的期盼和向往。可是天有不测风云，一场突如其来的疾病降临到我的身上，不得已发起这次筹款，还请大家帮帮我，助我们全家渡过这次难关！在${hospitalName}，我被诊断为${patient_disease}，听到这个消息，我如遭雷击，家中人口多，平时生活就很拮据，没有什么积蓄，如何承担的了这巨额费用？瞬间甚至想干脆放弃治疗，就这样结束了吧！但在亲友的帮助下，在医生的开导中，我逐渐重拾信心，是的，我不能让家里人失去依靠。` +
                `\n\n求助呼吁：\n` +
                `现在的医药花费，都是亲朋好友给凑的救命钱，根本无力承担后续高昂的治疗费用，考虑再三，决定在宠物筹发起这次筹款自救，还请大家帮帮我，待我度过这次难关，我一定会好好的回报社会，从大家手中接过这根爱心接力棒，将这份善良和爱心继续传递下去！之前也经常看到宠物筹帮助别人，但谁也没想到有一天我也会在宠物筹寻求爱心帮助，真心的祝愿大家保重身体，健康没了什么都是零，希望各位爱心人士能够理解我！\n爱出者，爱返，福往者，福来，万般无奈宠物筹款，恳请您在阅读完这篇求助信帮我们转发一下，便是对我们一家最大的帮助。爱心成海，汇聚浩瀚，筹集世间温度，让困难不再无助，无论您帮助与否，我都感谢您耐心的看完这封求助信，感谢您，祝您身体健康，一切顺利！`
        },
        {
            code: `34`,
            text: `风和日丽，鸟语花香，到处充满生机勃勃，看病房窗外到处的景色，才发现自己之前忽略了这些生活中的美好，若是能够早日康复，我一定好好的去珍惜生命中的一切！` +
                `\n\n患者病情介绍：\n` +
                `大家好，我叫${patient_name}${age ? '，今年'+age + '岁' : ''}，家住${address}，我今天为我自己发起筹款。 我感觉身体不对劲，就到了我们当地医院检查，结果被确诊为${patient_disease}！` +
                `\n\n患者家庭情况：\n` +
                `我的家庭是普通家庭，自己没有工作也没有经济来源，这次有了这个大病，医疗费手术费都是亲戚朋友凑的，后面还要化疗费用更大，现在面临治疗费用，家里经济一下陷入谷底。无奈想起宠物筹，病情危急，只能和大家求助！ 请大家帮我们渡过难关，我们以后有能力了，一定去帮助更多的人。` +
                `\n\n求助呼吁：\n` +
                `生活难免遭遇意外，也许您与我们素不相识 ， 但爱心与真诚是没有界限 ， 希望大家都能伸出援助之手 ， 帮助我的家人与病魔抗争 ， 也许这些捐款对您而言是一笔小小的支出 ， 但是这些积少成多的爱心 ， 就能换同您们一样健康的身体 ！ 我们急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!\n希望您能伸出援手帮帮我们，帮我们多多转发，多多证实朋友圈第一条，万分感谢！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助! 对资助过我从未谋面的陌生人以及亲朋好友们愿你们及家人一生平安，谢谢！`
        },

        {
            code: `35`,
            text: `在生命的危机时刻，那些素未谋面的爱心与善意如同骄阳似火，总能温暖我一家人的心灵！感谢大家在百忙之中抽出时间来阅读这一封求助信，感谢大家！` +
                `\n\n患者病情介绍：\n` +
                `大家好，我叫${patient_name}${age ? '，今年'+age + '岁' : ''}，家住${address}。天有不测风云，被确诊${patient_disease}。巨大的压力迫得我无法喘息，不得已向社会各界好心人寻求帮助……我实在是走投无路了，后续的治疗费用对我们这种普通的家庭来说实在无力承担，恳请各位好心人伸出援助之手，让我完成治疗，回到亲朋好友身边！您的每一次爱心帮助、每一次转发都非常重要，感谢各位好心人，谢谢大家了！` +
                `\n\n求助呼吁：\n` +
                `叶需要阳光的照耀，它从来不拒绝阳光。我们需要爱和爱心，也不会拒绝爱和爱心。 恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助! 万般无奈之下通过宠物筹发起爱心求助，望借助大家的力量能够帮帮我，您的每一次转发和捐款都将给绝望中的家庭带来更多的希望，我相信，也期待，我们可以一起去面对，战胜所有考验我们的困难，也希望各位有爱心的朋友们，能够给予我们更多的力量，更多的希望，哪怕是一句祝福鼓励，一次转发扩散，一次证实，对于我们来说，都是莫大的帮助。\n我们都会感激不尽，在今后，我们也会同大家一起，为爱加油助力，帮助更多的需要帮助的人，一起迎接挑战，战胜困难，迎接明天的阳光！恳请各位好心人士伸手相助，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助！`
        },
        {
            code: `36`,
            text: `以前我有许多美好的心愿：做老师，当科学家，去一次海边或快快长大，而当我经历许多事后，我唯一的心愿就是：家人幸福安康。如果让我许个能帮我实现的愿望，那一定会是家人身体健康……！` +
                `\n\n患者病情介绍：\n` +
                `我叫${patient_name}${age == 0 ? '不到一岁' : '，今年'+age + '岁'}，我们一家人住在${address}。我们一家人打工，平时也没有什么其他的收入来源，本来在不生病的情况下我们一家人的生活过得也算温饱。\n谁想到，我突然开始发病，随后到${hospitalName}检查，确诊为${patient_disease}，前期治疗费用都是找亲戚朋友借的钱动手术，后期化疗费用，我们实在没办法了，这样的重病于对我们这样的普通的家庭实在难以承担，家里负担实在太大，这对于我们简直就是天文数字。` +
                `\n\n求助呼吁：\n` +
                `只希望社会上的好心人士能看到这些文字，伸出援助之手，您的慷慨相助，您的一次转发对我都是无比的恩情，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助。待我度过这次难关，我一定会好好的回报社会，从大家手中接过这根爱心接力棒，将这份善良和爱心继续传递下去。在这里恳求各位好心人救救我，救救我，家里面不能没有我！我不想这么早就离开这美丽的世界，求各位好心人帮帮我 ，求求您们！祝愿好人一生平安！！！\n希望大家可以帮忙转发和证实，多一份转发就多一份希望，让更多人可以看到这条求助信息，祝愿全天下的好人都有好报，再次感谢大家！我急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!感谢好心人一生平安！`
        },
        {
            code: `37`,
            text: `风和日丽，鸟语花香，到处充满生机勃勃，看病房窗外到处的景色，才发现自己之前忽略了这些生活中的美好，若是能够早日康复，我一定好好的去珍惜生命中的一切！` +
                `\n\n患者病情介绍：\n` +
                `各位爱心人士，大家好！我叫${patient_name}${age ? '，今年'+age + '岁' : ''}，家住${address}，我今天为我自己发起筹款。我感觉身体不对劲，就到了我们当地医院检查，由于当地医院医疗条件有限，建议我们转到大医院治疗，我们立即去${hospitalName}检查，确诊为${patient_disease}，确诊之后医生马上给我安排了手术，手术后又开始了多期的化疗。` +
                `\n\n患者家庭情况：\n` +
                `我的家是普通家庭，目前由于患病自己没有工作也没有经济来源，这次得了这个病，医疗费手术费都是亲戚朋友凑的，后面的治疗费用更大。现在面临治疗费用，家里经济一下陷入谷底，无奈想起宠物筹，病情危机，只能和大家求助！` +
                `\n\n求助呼吁：\n` +
                `请大家帮我们渡过难关，我们以后有能力了，一定去帮助更多的人。生活难免遭遇意外，也许您与我们素不相识 ，但爱心与真诚是没有界限，希望大家都能伸出援助之手，帮助我和家人与病魔抗争，这些捐款对您而言只是一笔小小的支出，但是这些积少成多的爱心，就能帮我换回同您们一样健康的身体！我们急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光！`
        },
        {
            code: `38`,
            text: `各位好心人，你们好，很抱歉以这样的形式让大家认识我，感谢你们打开我的求助信。` +
                `\n\n患者病情介绍：\n` +
                `我叫${patient_name}${age ? '，今年'+age + '岁' : ''}，家住${address}，日子平平淡淡的也是幸福， 一场大病打破了我们平静的生活，被医生确诊为${patient_disease},家人竭尽所能的为我治疗，这一系列费用下来已将全部的积蓄花完。` +
                `\n\n患者家庭情况：\n` +
                `家庭的变故让我们措手不及。前期花费了我们全家所有积蓄加上借亲友的钱起来也不过杯水车薪。 医生说后面还需要一系列的治疗，费用还很高，家庭的经济实力已难以支撑下去，现发起筹款希望好心人能多帮帮我，不得不在这里借助宠物筹平台，向您求助，并没有想过能筹够钱，只是想所有可能的方式都尝试一下，和命运做一次搏斗。   希望各位有爱心的朋友，给我们更多的力量，让希望不再渺茫。` +
                `\n\n求助呼吁：\n` +
                `恳请各位好心人士伸手相助，多多转发，多多证实。您的每一次转发、证实我们都至关重要，每一次转发对我们来说都是莫大的帮助!恳请各位好心人士伸手相助，多多转发，多多转发。每一次转发对我们都至关重要，每一次转发证实我们来说都是莫大的帮助！我知道大家并不富裕，但聚沙成塔，集腋成裘，涓涓细流便可汇成江河。如果大家献出点滴的爱心，对于我们这个家庭来说就是生命的重生，我向大家表示衷心的感谢！万分感谢大家！！！！！！`
        },
        {
            code: `39`,
            text: `思量再三，实在是没有办法了，只能决定在这里发起求助。心里很难过，而当您打开这封求助信的时候您就是我的恩人。在这里，我要真诚的感谢您！` +
                `\n\n患者病情介绍：\n` +
                `大家好，我叫${patient_name}，家住${address}，我们是一个普通的家庭，从来没有想过以这种方式求助于大家，实在走投无路了，发起宠物筹，恳求大家能够伸出援助之手帮帮我！谢谢大家！虽然生活过的不富裕，但是一家人平平安安的还是觉得很幸福，但天总是不遂人愿，突然身体感到不适，我们立即到${hospitalName}进行检查，诊断为${patient_disease}。\n本身就没什么积蓄，接下来每天的医疗费都需要很多！后续需要长期的治疗和恢复，面对高额的后续医疗费，我深深感到无力感。万般无奈只好恳求社会各界的善良好心人帮帮我!` +
                `\n\n求助呼吁：\n` +
                `希望各位社会爱心人士帮我们家渡过难关，创造生命奇迹，哪怕是一分钱，一点祝福，也能给我们带来莫大的希望和鼓励！期盼社会好心人能伸出援助之手，大家的每一份爱心，我们都会铭记在心，我们会时刻谨记大家的帮助，尽我们所能回报社会！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!希望您能伸出援手帮帮我们，帮我们多多转发，多多证实朋友圈第一条，万分感谢！`
        },
        {
            code: `40`,
            text: `人生真的很脆弱，一场疾病跟意外就能将一个人的尊严摧毁，一刻还在阳光下，下一刻就只能待在昏暗的病房里，幸福的家庭大致相同，不幸的家庭各不相同，没有人愿意将自己的伤口揭开给别人看。面对疾病，“活着”显然变得如此奢侈，也如此昂贵，经历了疾病的痛苦，才能更加去珍惜！` +
                `\n\n患者病情介绍：\n` +
                `社会上的爱心人士您好！我叫${patient_name}，是${address}人，这是我无奈之下为自己发起的求助。在${hospitalName}进行检查，被诊断为${patient_disease}。后续还无法预估，我们实在不知道在哪里去找那么多钱，医疗费像一块沉重的巨石压在我们心头，是让人喘不过气来。` +
                `\n\n患者家庭情况：\n` +
                `我们只是个普通家庭，没有什么积蓄，现在治病的钱都是亲朋好友们到处借钱支付的，医药费完全没有着落。 在万不得已的情况下我们只能向社会进行求助！ 我们实在是无力承担后续治疗的费用 ！ 面对高昂的治疗费用，我感到如此的无能为力，实在无法承担，我很多次只能偷的抹眼泪。` +
                `\n\n求助呼吁：\n` +
                `无奈之下只能求助于平台，希望好心人能帮帮我，帮帮我这苦难的家庭。一两毛、一块两块的爱心只要聚集起来，就能给我这个苦难的家庭带来一份希望！好人一生平安，希望人人献出一点爱，人间可以是春天！在此，我先谢谢各位善良的好心人了！希望看到这里的好心人，能帮帮我，救救我们这一家人，谢谢你们！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要。`
        },
        {
            code: `41`,
            text: `各位好心人，你们好，我叫${patient_name}${age ? '，今年'+age + '岁' : ''}，刚被医生确诊为${patient_disease}，这一下压垮了我和我的家庭，本来幸福的家庭笼罩上了阴云，原来的欢歌笑语都不见了。多希望能有好心人救救我，让我可以活下去！谢谢大家了！` +
                `\n\n患者病情介绍：\n` +
                `我刚被医生确诊为${patient_disease}，这个疾病来得毫无预兆，拿到结果的时候我愣了好久，好多事情一下全部涌进脑子，不能继续正常生活，不能好好照顾⽗母，不能继续学习工作。确诊的第二天，爸爸就像苍老了二十岁，妈妈满⾯愁容却也打着精神笑着安慰我没事，让我好好做治疗，看着他们因我受苦，我就禁不住的心酸。医生告诉家里，后续的治疗还需要很⼤一笔钱，家⾥为我四处奔波，亲戚朋友也都尽力为我筹钱，我们实在不知道该怎么办了，走投⽆路才向大家求助。` +
                `\n\n求助呼吁：\n` +
                `希望各位可以帮帮我，我还想好好生活，我的未来还有很多无限的可能，我还想继续回报我的⽗母、回报社会，我想用全⼒和病魔斗争，用全⼒热爱⽣活。感谢每⼀位善良的人，哪怕只是转发和留言，对我都是莫大的支持与帮助。谢谢每一位伸出援手的好心人，愿善念永存，愿爱心传递!\n每一次转发对我们都至关重要，每一次转发证实我们来说都是莫大的帮助！我知道大家并不富裕，但聚沙成塔，集腋成裘，涓涓细流便可汇成江河。如果大家献出点滴的爱心，对于我们这个家庭来说就是生命的重生，我向大家表示衷心的感谢！万分感谢大家！`
        },
        {
            code: `42`,
            text: `病魔无情，人间有爱，酌思甚久，本不想以这种形式打扰各位，人生真的很脆弱，一场大病就能将一个人的尊严毁灭，前一刻还在阳光下，下一刻就只能待在昏暗的病房里，没有人愿意将自己伤口揭开给别人看。除非山穷水尽，走投无路。首先感谢各位好心人士百忙之中阅读我这封求助信！` +
                `\n\n患者病情介绍：\n` +
                `我叫${patient_name}${age ? '，今年'+age + '岁' : ''}，来自${address}，今天我要在宠物筹平台向您发起求助是为了我能够活下去，家里平静的生活被这突如其来的疾病所打破，来到${hospitalName}就诊，结果诊断为${patient_disease}。` +
                `\n\n患者家庭情况：\n` +
                `没有太多的收入，这一得病，就立即掏空了家里的所有积蓄，家里人为了照顾我，也都没有了稳定的收入，为了治病已经向亲朋好友借了钱，家里实在承担不住，万般无奈之下只有借助宠物筹平台向您发起求助` +
                `\n\n求助呼吁：\n` +
                `希望您能伸出援手帮帮我们，帮我们多多转发，多多证实朋友圈第一条，万分感谢！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!\n对资助过我从未谋面的陌生人以及亲朋好友们愿你们及家人一生平安，谢谢！一两毛、一块两块的爱心只要聚集起来，就能给我这个苦难的家庭带来一份希望！好人一生平安，希望人人献出一点爱，人间可以是春天！在此，我先谢谢各位善良的好心人了！希望看到这里的好心人，能帮帮我，救救我们这一家人，谢谢你们！`
        },
        {
            code: `43`,
            text: `花要叶扶，人要人帮；赠人玫瑰，手有余香；滴滴甘霖，汇成海洋。 我很抱歉以这样的方式进入大家的眼球。` +
                `\n\n患者病情介绍：\n` +
                `大家好，我叫${patient_name}${age ? '，今年'+age + '岁' : ''}，家住${address}，每月收入不多，但生活过得也算幸福美满，可是一场疾病却悄然而至，我因身体不适到${hospitalName}就诊，被诊断为${patient_disease}，为治病，家里积蓄花完了，亲戚朋友借遍了，微薄的工资在疾病面前只是杯水车薪，这漫长的治疗过程一度让我精神崩溃，但是看到爱我，支持我的家人们，我一定要努力对抗病魔。` +
                `\n\n患者家庭情况：\n` +
                `曾几何时，我也和您一样从不相信疾病会与自己对号入座，可是上天就是如此残忍，突如其来的一场大病压的我们全家连气都喘不过来。 大病降临，便是一场金钱与生命的较量。 人生最贵的一张床，没人睡得起，我们家里都是普通人，面对后期的费用，我们倍感压力，实在不知如何是好，请大家帮我度过难关，我们全家将感恩您的大恩大德！我将用一生回报社会！` +
                `\n\n求助呼吁：\n` +
                `恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助。我们急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光! 请您留下您的全名，往后余生我及我的家人将会铭记在心。`
        },
        {
            code: `44`,
            text: `思量再三，实在是没有办法了，只能决定在这里发起求助。心里很难过，而当您打开这封求助信的时候您就是我的恩人。在这里，我要真诚的感谢您！` +
                `\n\n患者病情介绍：\n` +
                `我是${patient_name}${age ? '，今年'+age + '岁' : ''}，家住${address}，我们家是一个普通的农村家庭，日子虽然平凡普通，但是一家人平安健康的在一起是很幸福的。\n意外的发生从不提前告知，最近这段时间我总是觉得胸闷气短，走路没劲儿，而且开始四肢浮肿，去我们县医院检查，县医院说病情比较严重，让赶快往上级医院转诊治疗，经过一系列检查确诊为${patient_disease}!` +
                `\n\n患者家庭情况：\n` +
                `当我拿到检查报告的那一刻，犹如晴天霹雳，${patient_disease}，这个平时只在电视上听过的病名怎么会发生在我的身上？\n我是家里的顶梁柱，一定要坚强起来战胜病魔!无奈我们家只是普通的农村家庭，现在我已经开始进行治疗，面对高昂的治疗费用，我们家实在是无力承担!` +
                `\n\n求助呼吁：\n` +
                `万般无奈之下发起宠物筹筹款，真诚的希望各位好心人帮帮我，感谢您们的雪中送炭，您的每次转发，对我的家庭来说是莫大的帮助，愿每位好心人身体健康，万事如意！\n恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },
    ];
    const childTemplates = [{
            code: `45`,
            pronoun: `她`,
            text: `各位爱心人士大家好!我女儿${patient_name}${age == 0 ? '不到一岁' : '，今年'+age + '岁'}，家住${address}。现在正在医院住院治疗，目前病情为：${patient_disease}，多么希望女儿能一直健康陪着我们。女儿突然发病，到医院后治疗后医生说续费用很大，我都不敢想象以后的治疗之路会有多曲折，不到万不得已，我们也不想发起筹款，可庞大的治疗费用。让我们寸步难行。` +
                `\n\n求助呼吁：\n` +
                `无奈之下只能求助于宠物筹发起筹款，希望大家能够帮助我女儿渡过这次难关！ 希望您能帮忙转发扩散这条求助信息，让爱心传递到每个角落。在这里，深深感谢每一位帮助过我们的亲朋好友，感谢每一位无私转发的爱心人士。授人玫瑰，手有余香;奉献爱心，收获希望。我们急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!\n也真心的希望大家要保重身体，健康没了什么都是零，最后恳请您在阅读完这篇求助信帮我转发一下，您的每一次转发都是我活下去的希望，感谢所有爱心人士！`
        },

        {
            code: `46`,
            pronoun: `她`,
            text: `医院!很多人脑海中，都浮现出熙攘的人群夹杂着刺鼻药水的冰冷画面，医院的墙承载了多少祷告，同时就见证了多少心酸。` +
                `\n\n患者病情介绍：\n` +
                `各位爱心人士！大家好！我女儿叫${patient_name}${age == 0 ? '不到一岁' : '，今年'+age + '岁'}，家住${address}，今天我为我女儿求助！她现在身患${patient_disease}！这结果让我们全家都陷入绝望。擦干眼泪只能在医院配合治疗。这一系列费用下来已将全部的积蓄花完，家庭的变故让我们措手不及。${cost_amount ? '前期花费了'+cost_amount + '元，' : ''}现在我们全家所有积蓄加上借亲友的钱，也不过杯水车薪。家里无力负担，现在家庭确实十分艰难，所以向社会求助！` +
                `\n\n患者家庭情况：\n` +
                `现在正是需要花钱的时候，一家人的生活靠微薄的收入艰难的维持！虽然不富裕，但是很充实！由于后期费用巨大，无奈发起筹款！我必须接受这个事实，治疗费用对于我们这种困难的家庭来说负担极其重，并且是一个长期治疗的过程，我们家里确实很困难！已经搞得我心力交瘁，巨大的困难接踵而至，医院后期治疗费用真的是雪上加霜！` +
                `\n\n求助呼吁：\n` +
                `面对后面巨额的治疗费用，让我不堪重负，我已无力负担，治疗所需要的费用对我们困难的家庭来说，这是一笔巨款，我只能求助于各位好心人士帮帮我，在此感谢大家了！我们急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!`
        },

        {
            code: `47`,
            pronoun: `她`,
            text: `希望在读这一封求助信的您，给我们这个家一丝丝的温暖!非常抱歉用这样的方式打扰到您！` +
                `\n\n患者病情介绍：\n` +
                `患者叫${patient_name}，是我的女儿${age == 0 ? '不到一岁' : '，今年'+age + '岁'}，我们家在${address}，我们是勤劳朴实善良的人，虽然日子苦点，但是是幸福的快乐的，因为有很多人关心着我们。家境贫寒，突遇重病，曾几何时，我也和您一样从不相信疾病会与亲人对号入座，可是上天就是如此残忍，突如其来的一场大病压的我们全家连气都喘不过来！` +
                `\n\n患者家庭情况：\n` +
                `病倒一人，目前家庭状况已经不能维持治疗，医院里花钱如流水，本不想麻烦大家，医院也一直催缴这治疗费用，亲戚朋友也都在尽心尽力的帮我们！可是治疗花费还是有很大的缺口！实在没有办法了只能想到求助宠物筹平台，恳请好心人帮帮我渡过难关。帮帮我的女儿！人间有大爱，感谢您的帮助 感谢您带给我们的希望。` +
                `\n\n求助呼吁：\n` +
                `女儿，你尽快的好起来吧，我们都需要你！多一份转发就多一份希望，接力点亮生命的希望之光！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!感谢每一位善良的人，哪怕只是转发和留言，对我都是莫大的支持与帮助。谢谢每一位伸出援手的好心人，愿善念永存，愿爱心传递！希望您能伸出援手帮帮我们，帮我们多多转发，多多证实朋友圈第一条，万分感谢！`
        },
        {
            code: `49`,
            pronoun: `她`,
            text: `各位爱心人士你们好！感谢您百忙之中抽出时间打开我的求助信！` +
                `\n\n患者病情介绍：\n` +
                `很无奈在这里通过这样的方式向大家发起爱心求助，此时此刻面对着眼下的考验，我真的不知道该怎么办了。家住${address}。我女儿叫${patient_name}${age == 0 ? '不到一岁' : '，今年'+age + '岁'}，被诊断为：${patient_disease}。 女儿这次入院手术，都是靠我们亲戚朋友拼拼凑凑，我们不忍心女儿被折磨而放任不管，就算再艰难也拼命咬牙坚持……` +
                `\n\n患者家庭情况：\n` +
                `然而每日增长的医疗费用，我们实在是难以支撑。 医院已经给出确切的治疗方案，后续需要进行长期的住院观察治疗，我们已经拿不出更多的钱来维持后期高额的医疗费用，各位善良好心的人们，若不是已山穷水尽，若不是正尴尬拮据，也不会走筹款这一步，恳请你们能够伸出援手帮我们一把，让倍受折磨的女儿能接受治疗！不管怎样只要有一丝希望，我们绝不放弃！也请您坚持下去，做我们一直的坚强斗士！` +
                `\n\n求助呼吁：\n` +
                `如果有幸此次得到帮助，我们将没齿不忘各位爱心人士的大恩大德！我们将义无反顾、坚持到底，我们相信只要坚持就有希望，只要人人奉献出一点爱，胜利终将属于我们！谢谢您的关心和帮助，哪怕只是一次转发，感恩，好人一生平安！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!谢谢！ 此致敬礼！`
        },
        {
            code: `50`,
            pronoun: `她`,
            text: `很抱歉，打扰大家了，麻烦大家看到信息的帮我转发一下为挽救我女儿发的求助信息，您的举手之劳能为我女儿带来生的希望，麻烦认识我的亲戚朋友实名验证一下情况属实，跪谢。` +
                `\n\n患者病情介绍：\n` +
                `大家好，我的女儿叫${patient_name}${age == 0 ? '不到一岁' : '，今年'+age + '岁'}，我们家住${address}，虽然家庭不富裕，但这我们的日子过的很幸福，但是事与愿违，疾病的到来让我们的家庭陷入深深的困境！我带着女儿到医院做了各项检查，女儿被确诊为${patient_disease}，我们家人听到这个结果后万分伤心，经过家人们一起商量，就算砸锅卖铁也要进行治疗，如果不治疗这将是我一生的遗憾。\n医生说要想治疗，这个高额的治疗费真的难倒了我们！ 思量再三，万般无奈下，我发起这次求助。恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!我们急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!` +
                `\n\n求助呼吁：\n` +
                `爱出者，爱返，福往者，福来，万般无奈宠物筹款，恳请您在阅读完这篇求助信帮我们转发一下，便是对我们一家最大的帮助。星光点点，汇聚浩瀚，筹集间温度，让困难不再无助，无论您帮助与否，我都感谢您耐心的看完这封求助信，感谢您，祝您身体健康，一切顺利！`
        },
        {
            code: `51`,
            pronoun: `她`,
            text: `星星之火可以燎原，颗颗爱心变希望。 非常抱歉用这种方式打扰到你们！同时也万分感谢好心的你打开我的求助信，感谢你们，希望你们帮帮我，帮帮我女儿。` +
                `\n\n患者病情介绍：\n` +
                `我女儿叫${patient_name}${age == 0 ? '不到一岁' : '，今年'+age + '岁'}，现在得了${patient_disease}，我们是${address}人，一家人过得不富裕但也算幸福。\n目前还有希望我们不想放弃。 家里真的有困难没办法只能找大家帮帮我们。 我知道大家并不富裕，但聚沙成塔，集腋成裘，涓涓细流便可汇成江河。如果大家献出点滴的爱心，对于我们这个家庭来说就是生命的重生，我向大家表示衷心的感谢！` +
                `\n\n求助呼吁：\n` +
                `特此借助【宠物筹】筹款平台向您发起求助，盼望您能伸出援手，助我们一家度过危难，也希望您能帮忙转发扩散这条求助信息，让爱心传递到每个角落。在这里，深深的感谢每一位帮助过我们的亲朋好友，感谢每一位无私转发的爱心人士，让生命得以延续，让爱心充满人间，感激不尽！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!\n也真心的希望大家要保重身体，健康没了什么都是零，最后恳请您在阅读完这篇求助信帮我转发一下，您的每一次转发都是我活下去的希望，感谢所有爱心人士！`
        },
        {
            code: `52`,
            pronoun: `她`,
            text: `尊敬的爱心人士您好！ 很抱歉我以这样的方式出现在大家的面前，家住 ${address}，这一次也是万般无奈！为我的女儿求助大家！ 我的女儿 ${patient_name}${age == 0 ? '不到一岁' : '，今年'+age + '岁'}，目前正在医院治疗，情况严重，目前在医院接受治疗，除去医保报销完，现在女儿的情况还是比较严重，确实不知道该怎么办了，现在看着她被疾病折磨，我却不知道该怎么办！` +
                `\n\n患者家庭情况：\n` +
                `万般无奈！通过宠物筹平台求助大家！ 说起家境，女儿身体一直不好，确实也没有办法了！才通过宠物筹平台求助大家！希望大家可以帮助我们度过难关！ 但是我时常在想，是不是普通的家庭面对疾病只能举手投降……毫无对策！\n辗转得知当今社会有宠物筹大病救助筹款这个平台，怀着一颗期盼的心，恳求得到社会各界好心人的帮助！哪怕是一元，两元都是满满的爱！一点一滴，聚沙成塔！赠人玫瑰，手留余香！我真诚的希望得到大家的帮助！ 再次感谢大家，我一生最重要的决定是保护家庭、关爱家人，我有缘能尽力之所怀服务社会。能和每一位有心人一起弘扬、对这福份感刻至深。 最后，祝愿天下好人一生平安健康！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要！` +
                `\n\n求助呼吁：\n` +
                `一两毛、一块两块的爱心只要聚集起来，就能给我这个苦难的家庭带来一份希望！好人一生平安，希望人人献出一点爱，人间可以是春天！在此，我先谢谢各位善良的好心人了！希望看到这里的好心人，能帮帮我，救救我们这一家人，谢谢你们！`
        },

        {
            code: `53`,
            pronoun: `她`,
            text: `人生百态，或喜或悲，在不同的人生阶段会有不一样的经历，其实这些年的我经历很多，非常难，很多人也给了我帮助！真的非常感激他们，但是现在还是遇到了无法逾越的鸿沟，实在没办法面对现在所患有的疾病，请求大家的帮助，发起宠物筹来求助各位好心人，请大家帮帮我们！` +
                `\n\n患者病情介绍：\n` +
                `各位爱心人士，您们好，感谢您们打开我的求助信，我想请各位好心人帮帮我的女儿${patient_name}${age == 0 ? '不到一岁' : '，今年'+age + '岁'}，我们是${address}人，天有不测风云，我女儿身体突然不适，于是带她一起去医院做检查，看着躺在病床上面色蜡黄的女儿，我多希望自己能帮他承担一分病痛也好！` +
                `\n\n患者家庭情况：\n` +
                `从确诊到现在，后续治疗费用是个无底洞，面对病魔我感觉到了从未有过的压力，只能竭尽所能的治疗，希望她能尽快好起来。 医院高昂的治疗费用，对于我们普通家庭来说是个天文数字，我们实在承受不起，希望大家帮帮我们！` +
                `\n\n求助呼吁：\n` +
                `平日里我也一直在尽自己所能帮助他人，我们不愿意麻烦别人，此次也是万般无奈才向各位好心人求助，恳求大家帮帮我，救救我的女儿，您的关心和帮助，我和家人都没齿难忘，您的每一次转发都是无比温暖，您的每一份爱心都能给我们家带来生的希望，谢谢你们，好人一生健康幸福平安！我们急需您的帮助！非常感谢爱心人士！`
        },

        {
            code: `54`,
            pronoun: `她`,
            text: `各位爱心人士你们好！感谢您百忙之中抽出时间打开我的求助信！` +
                `\n\n患者病情介绍：\n` +
                `我的女儿叫${patient_name}${age == 0 ? '不到一岁' : '，今年'+age + '岁'}，家在${address}。我们一家人虽不是特别富裕，但我们生活也有目标。但天有不测风云，面对这个检查结果，我们真的蒙了！这么严重的疾病为什么就偏偏落在了我女儿身上。\n家里动员了所有人，可还是堵不住住院单上的数字。我该去哪儿凑这么多钱？我真的很难过，难过的是自己为什么没能多挣一些钱，为什么看着病床上急需治疗的女儿却想不出办法。他就是我的命我的全部，如果可以我愿意拿命换他一身平安，真的不能失去她，还有大好的人生。` +
                `\n\n患者家庭情况：\n` +
                `身后还有一家老老小小，可我现在连一边都顾不过来......所以在这里恳请各位好心人士伸手相助，哪怕只是一分钱，也可以帮我留住女儿的命！您的每一分爱心、每一次转发我都将心存感恩，永世铭记！恳请好心人伸出援手，一两毛、一块两块的爱心只要聚集起来，就能给我这个苦难的家庭带来一份希望！` +
                `\n\n求助呼吁：\n` +
                `希望大家可以帮忙转发和证实，多一份转发就多一份希望，让更多人可以看到这条求助信息，祝愿全天下的好人都有好报，再次感谢大家！我急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!感谢好心人一生平安！`
        },
        {
            code: `56`,
            pronoun: `她`,
            text: `面对疾病，“活着”显然变得如此奢侈，也如此昂贵，经历了疾病的痛苦，才能更加去珍惜！ 尊敬的亲朋好友，社会爱心人士，您好！ 当您打开这封求助信时，您就是我们家的大恩人了。` +
                `\n\n患者病情介绍：\n` +
                `患者是我女儿，名叫${patient_name}${age == 0 ? '不到一岁' : '，今年'+age + '岁'}。家住${address}！ 没想到我会通过这样的方式打扰大家： 我女儿突发身体不适，经医生检查确诊为${patient_disease}。需要立即手术，才能维持生命！我们就是一个普通的家庭，没有过多地积蓄，我实在是拿不出来了，真的是手足无措，唯有以泪洗面！女儿得了这种疾病，我真的是没有办法了！不得已求助宠物筹这个平台！突如其来的意外让家里的生活不堪重负，真的万般无奈，才能通过宠物筹这个平台打扰大家，希望大家帮忙转发扩散。不胜感激！` +
                `\n\n求助呼吁：\n` +
                `我郑重承诺：在接受了所有爱心人士的帮助以后，我们家以后一定会回馈社会，积极参与社会公益事业，把这份爱心传递下去，帮助更多有需要的人。恳请各位好心人士伸手相助，转发扩散，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!我急需您的帮助！万分感谢！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!感谢好心人一生平安！`
        },
        {
            code: `57`,
            pronoun: `她`,
            text: `其实在很多时候，人们总是微小如砂砾，上天并不能看到每个人所面临的痛苦，而阳光也有洒不到的角落。世间的温暖更多的是来自相互的给予。当您在阅读这封信时，也许我们是认识的，也许您我只是相隔数万里的陌生人。但我希望您依然继续阅读下去，谢谢您！` +
                `\n\n患者病情介绍：\n` +
                `各位爱心人士大家好!我女儿${patient_name}${age == 0 ? '不到一岁' : '，今年'+age + '岁'}，家住${address}。现在正在医院住院治疗，目前病情为：${patient_disease}，多么希望女儿能一直健康陪着我们。女儿突然发病，到医院后治疗后医生说续费用很大，我都不敢想象以后的治疗之路会有多曲折，不到万不得已，我们也不想发起筹款，可庞大的治疗费用。让我们寸步难行。\n无奈之下只能求助于宠物筹发起筹款，希望大家能够帮助我女儿渡过这次难关！ 希望您能帮忙转发扩散这条求助信息，让爱心传递到每个角落。` +
                `\n\n求助呼吁：\n` +
                `在这里，深深感谢每一位帮助过我们的亲朋好友，感谢每一位无私转发的爱心人士。授人玫瑰，手有余香;奉献爱心，收获希望。我们急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!\n也真心的希望大家要保重身体，健康没了什么都是零，最后恳请您在阅读完这篇求助信帮我转发一下，您的每一次转发都是我活下去的希望，感谢所有爱心人士！`
        },

        {
            code: `58`,
            pronoun: `她`,
            text: `思量再三，迫于无奈发起宠物筹款 ，我们是北京市北京市朝阳区人。尊敬的亲朋好友，以及尊敬的社会爱心人士，原谅我打扰你们幸福甜蜜的生活。病魔所困，实在无奈，为了女儿生命的延续，怀着忐忑而急切的心情，向您真诚求助！在这生命中的至暗时刻，内心所有的牵挂都集中在了你的身上。` +
                `\n\n患者病情介绍：\n` +
                `患者是我的女儿${age == 0 ? '不到一岁' : '，今年'+age + '岁'}，检查确诊为${patient_disease}，当医生告知我们这诊断结果后，我们忍不住抱头大哭。这个消息犹如晴天霹雳，给了我们经济上和精神上的双重打击，对于我们这个本就不宽裕的家庭无异于雪上加霜。` +
                `\n\n患者家庭情况：\n` +
                `现在我们这个普通的家庭已是山穷水尽。但孩子的路还很长，就算跪着走也要走下去，只为让女儿活下去，面对眼前天价般的医疗费真的不亚于泰山压顶，希望可以依靠社会的力量，度过这个难关。` +
                `\n\n求助呼吁：\n` +
                `我承诺所筹款项全部用于孩子的治疗费用，如果有剩余我会原路返还！今生我没有太多的奢求，只希望女儿能够挺过这一关，我只想一家人能够在一起生活，苦点累点我们都不怕。我由衷地恳求大家帮帮我们，帮帮我们这个家！只有大家的帮忙，我们才能度过这次劫难，也希望有大家的支持、帮助与鼓励给我们带来战胜病魔的勇气和力量，涅槃重生！此恩永记！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },
        {
            code: `59`,
            pronoun: `他`,
            text: `希望在读这一封求助信的您，给我们这个家一丝丝的温暖!非常抱歉用这样的方式打扰到您！` +
                `\n\n患者病情介绍：\n` +
                `患者叫${patient_name}，是我的儿子${age == 0 ? '不到一岁' : '，今年'+age + '岁'}，我们家在${address}，我们是勤劳朴实善良的人，虽然日子苦点，但是是幸福的快乐的，因为有很多人关心着我们。家境贫寒，突遇重病，曾几何时，我也和您一样从不相信疾病会与亲人对号入座，可是上天就是如此残忍，突如其来的一场大病压的我们全家连气都喘不过来！` +
                `\n\n患者家庭情况：\n` +
                `病倒一人，目前家庭状况已经不能维持治疗，医院里花钱如流水，本不想麻烦大家，医院也一直催缴这治疗费用，亲戚朋友也都在尽心尽力的帮我们！可是治疗花费还是有很大的缺口！` +
                `\n\n求助呼吁：\n` +
                `实在没有办法了只能想到求助宠物筹平台，恳请好心人帮帮我渡过难关。帮帮我的儿子！人间有大爱，感谢您的帮助 感谢您带给我们的希望，儿子，你尽快的好起来吧，我们都需要你！多一份转发就多一份希望，接力点亮生命的希望之光！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!感谢每一位善良的人，哪怕只是转发和留言，对我都是莫大的支持与帮助。谢谢每一位伸出援手的好心人，愿善念永存，愿爱心传递！\n希望您能伸出援手帮帮我们，帮我们多多转发，多多证实朋友圈第一条，万分感谢！`
        },

        {
            code: `61`,
            pronoun: `他`,
            text: `医院!很多人脑海中，都浮现出熙攘的人群夹杂着刺鼻药水的冰冷画面，医院的墙承载了多少祷告，同时就见证了多少心酸。` +
                `\n\n患者病情介绍：\n` +
                `各位爱心人士！大家好！我儿子叫${patient_name}${age == 0 ? '不到一岁' : '，今年'+age + '岁'}！今天我为我儿子求助！他现在身患${patient_disease}！由于现在没有能力，自己家里无力负担，现在家庭确实十分艰难，所以向社会求助！` +
                `\n\n患者家庭情况：\n` +
                `家住${address}，一家人的生活就靠我一个人微薄的收入艰难的维持！虽然不富裕，但是很充实！\n孩子因为身体不适，由于后期费用巨大，无奈发起筹款！我必须接受这个事实，治疗费用对于我们这种困难的家庭来说负担极其重，并且是一个长期治疗的过程，我们家里确实很困难！已经搞得我心力交瘁，巨大的困难接踵而至，医院后期治疗费用真的是雪上加霜！面对后面巨额的治疗费用，让我不堪重负，我已无力负担，治疗所需要的费用对我们困难的家庭来说，这是一笔巨款，我只能求助于各位好心人士帮帮我，在此感谢大家了！我们急需您的帮助！接力点亮生命的希望之光!需您的帮助！` +
                `\n\n求助呼吁：\n` +
                `希望您能伸出援手帮帮我们，帮我们多多转发，多多证实朋友圈第一条，万分感谢！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助! 对支助过我从未谋面的陌生人以及亲朋好友们愿你们及家人一生平安，谢谢！`
        },

        {
            code: `62`,
            pronoun: `他`,
            text: `大家好！无奈发起筹款是为了挽救可怜的儿子${patient_name}。儿子因突然患有${patient_disease}。我们来自${address}。` +
                `\n\n患者病情介绍：\n` +
                `非常抱歉在打扰到大家，发起此次筹款实属无奈，恳请大家帮帮我们度过难关，天有不测风云，人有旦夕祸福！儿子身体不适，我们来自一个普通家庭${(age != 0)&&age<=20 ? '，儿子才'+age + '岁，本来应该是最好的年华' : ''}，从意外发生到现在， 医生告诉我们要治疗很长的时间，在这期间我们要持续的在医院进行治疗。这已经不是我们现在的家庭能够承担的。 我的儿子还应该有很长的路要走，他有自己的梦想要去完成，但还未启程就遭遇了这样的意外。无论如何，我也不想看着他因为没有治疗费用而倒在病床上！看着儿子在病床上仍然在关心我的时候，我更是心如刀绞，每天都陷入深深的自责与焦虑之中。` +
                `\n\n求助呼吁：\n` +
                `万般无奈之下联系到了宠物筹平台，希望通过这个平台给我们带来一丝希望。恳请各位好心人能够伸出援手！无论是您慷慨解囊，还是帮忙转发，还是一句祝福，都是给我们的一份希望，一次重生的机会，谢谢！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },

        {
            code: `63`,
            pronoun: `他`,
            text: `思量再三，迫于无奈发起宠物筹款 ，我是${address}人。尊敬的亲朋好友，以及尊敬的社会爱心人士，原谅我打扰你们幸福甜蜜的生活。病魔所困，实在无奈，为了儿子生命的延续，怀着忐忑而急切的心情，向您真诚求助！在这生命中的至暗时刻，内心所有的牵挂都集中在了你的身上。` +
                `\n\n患者病情介绍：\n` +
                `患者是我的儿子${age == 0 ? '不到一岁' : '，今年'+age + '岁'}，当医生告知我们这诊断结果后，我和忍不住抱头大哭。因为他是孩子，他的路还很长，就算跪着走也要走下去，只为让儿子活下去，面对眼前天价般的医疗费真的不亚于泰山压顶，希望可以依靠社会的力量，度过这个难关。` +
                `\n\n求助呼吁：\n` +
                `我承诺所筹款项全部用于孩子的治疗费用，如果有剩余我会原路返还！今生我没有太多的奢求，只希望儿子能够挺过这一关，我只想一家人能够在一起生活，苦点累点我们都不怕。我由衷地恳求大家帮帮我们，帮帮我们这个家！\n只有大家的帮忙，我们才能度过这次劫难，也希望有大家的支持、帮助与鼓励给我们带来战胜病魔的勇气和力量，涅槃重生！此恩永记！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },
    ];
    const coupleTemplates = [{
            code: `66`,
            pronoun: `他`,
            text: `只有经历了疾病的痛苦，才明白健康的可贵！面对着生与死之间的距离，“健康活着”变的如此奢侈，如此昂贵！` +
                `\n\n患者病情介绍：\n` +
                `尊敬的各位爱心人士，你们好！患病的是我老公${patient_name}${age ? '，今年'+age + '岁' : ''}，家住${address}，感谢大家抽出宝贵的时间来阅读这封求助信，希望您能伸出爱心之手，来帮帮我们这个不幸的家庭，筹得善款医治他的身体。\n前一阵子，他突然身体不适，晕倒在地，一检查就是${patient_disease}，面对高昂的医药费，对于我们一个本就负债累累的家庭无疑是晴天霹雳，家里两个老人一直在用药，孩子又总是生病，现在老公已经确诊，我不得挑起家庭的重担，作为顶梁柱负担起这个家，现在最大的心愿就是希望老公的病能够得到顺利医治，只是我该上哪儿筹到这么多医疗费！` +
                `\n\n求助呼吁：\n` +
                `辗转知道宠物筹平台，抱这一份希望特恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助，哪怕是筹到一块钱，我们也是感激不尽！愿你们无病无灾，幸福安康！恳请各位好心人士伸手相助，多多转发，我们急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!`
        },
        {
            code: `67`,
            pronoun: `他`,
            text: `疾病的降临总是悄无声息，躲不过也逃不开，能做的只有迎难而上！人生有时候真的很脆弱，前一刻还在阳光下，下一刻就只能待在昏暗的病房里，幸福的家庭大致相同，不幸的家庭各有各的不幸！` +
                `\n\n患者病情介绍：\n` +
                `家住${address}。我想为我的老公求助，我的老公叫${patient_name}${age ? '，今年'+age + '岁' : ''}！老公突然身体不适，医生给他做了进一步检查，说他疑似${patient_disease}，医院不敢接收！最终确诊为${patient_disease}，我听到这个消息，犹如晴天霹雳！可是残酷的现实不会向我们低头，强迫我们必须坚强的去面对！` +
                `\n\n患者家庭情况：\n` +
                `我们就是普普通通的家庭，孩子还小，全靠丈夫外出打工维持家用。现在丈夫生病了，也没有了经济来源。后面还有好几期的治疗等待着老公，如果效果不缓解，还需要进一步治疗，后期费用不可预算，借遍了身边亲戚朋友。真的不知道该怎么办了，我们一家人跪求社会好心人士，救救我的老公，救救我可怜孩子的爸爸，我和孩子不能失去他啊！能多筹一块钱他就有活下去的希望，我代表我们全家人跪谢您！ 生活中的很多事，常常不是以人的愿望而有所改变，前面的路怎样我不可预知，但是我不想放弃，我想让老公活下去！我深信大爱无疆，这是个有爱的社会。` +
                `\n\n求助呼吁：\n` +
                `各位的恩情我必定铭记于心，不管结果如何以后我跟我家人一定会用自己的爱心回报这个社会！感谢医院所有的医护人员，感谢所有关心老公的爱心人士！同时恳请您能帮忙转发扩散这条求助信息，让爱心传递到每个角落。让生命得以延续，让爱充满人间，感激不尽！您的每一次转发对我老公都至关重要，因为您每一次转发对我老公都多了一份生的希望！病魔无情，人间有爱，恳请各位好心人士伸手相助，多多转发，您的每一次转发和认证对我老公都至关重要，每一次转发对我老公来说都是莫大的帮助!我也必然不会忘记您们的恩情！祝好人一生平安!`
        },
        {
            code: `68`,
            pronoun: `他`,
            text: `人生真的很脆弱，一场疾病跟意外就能将一个人的尊严摧毁，这一刻还在阳光下，下一刻就只能待在昏暗的病房里，幸福的家庭大致相同，不幸的家庭各不相同，没有人愿意将自己的伤口揭开给别人看。面对疾病，“活着”显然变得如此奢侈，也如此昂贵，经历了疾病的痛苦，才能更加去珍惜！` +
                `\n\n患者病情介绍：\n` +
                `各界的社会爱心人士，您们好! 也许只需要您一瓶饮料，一顿快餐，一包香烟的费用，但您的一个小小举动，就能帮助到一个家庭脱离病魔的纠缠！\n患者是我的丈夫${patient_name}${age ? '，今年'+age + '岁' : ''}，家住${address}。我们是普普通通老百姓，生活虽然不富裕，但能勉强维持生活。丈夫感觉身体不舒服，医院检查最后是${patient_disease}。` +
                `\n\n患者家庭情况：\n` +
                `听到这个消息，让我们真是坐立不安，原本就生活不富裕的我们，如今又是雪上加霜。医生建议马上治疗。面对这个消息和现实的问题，我真不知道该怎么办了。丈夫生病后，已经花光了家里所有的积蓄费，乃至更多。面对这一切我们倍感压力，迫于无奈求助社会！` +
                `\n\n求助呼吁：\n` +
                `恳请各位好心人帮助我，哪怕是一个转发一次鼓励对我也是莫大的支持。我们急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },
        {
            code: `70`,
            pronoun: `他`,
            text: `大家好，我老公是患者，叫${patient_name}，我们是${address}人，我这次是为我的老公发起筹款，老公是家里的顶梁柱，现在生病整个家都垮了。	` +
                `\n\n患者病情介绍：\n` +
                `最近，我老公突然感觉身体不适，因为病情严重导致老公意识不太清楚，我们不能及时有效沟通，医生诊断为${patient_disease}，建议我们马上治疗，做了治疗，但是治疗后，需要恢复期和进行康复治疗。` +
                `\n\n患者家庭情况：\n` +
                `在老公治疗的这段时间，我都不知道自己是怎么过来的，后面一系列的治疗费用，家庭的经济实力已难以支撑下去，现发起筹款希望好心人能多帮帮我，不得不在这里借助宠物筹平台，向您求助，并没有想过能筹够钱，只是想尽所有可能和命运做一次搏斗。` +
                `\n\n求助呼吁：\n` +
                `希望各位有爱心的朋友，给我们更多的力量，让希望不再渺茫。 恳请各位好心人士伸手相助，多多转发，多多证实。您的每一次转发、证实我们都至关重要，每一次转发对我们来说都是莫大的帮助!我知道大家并不富裕，但聚沙成塔，集腋成裘，涓涓细流便可汇成江河。如果大家献出点滴的爱心，对于我们这个家庭来说就是生命的重生，我向大家表示衷心的感谢！万分感谢大家！让我老公能够早日康复，挽救我们这个家。请大家多多转发证实，让更多的人看到，给我们一点点希望，让爱心汇聚！谢谢大家了，好人一生平安！`
        },
        {
            code: `71`,
            pronoun: `他`,
            text: `当您打开这篇文章的时候您就是我们的恩人，无论您有没有捐助我们，有没有给我转发，我们都要在此感谢您！！！` +
                `\n\n患者家庭情况：\n` +
                `万万没想到我老公检查结果是${patient_disease}，这段时间一直住院治疗，每天的治疗费都很昂贵，我们是一个普普通通的家庭，孩子还要花钱，老公病了以后，现在家里没有任何经济来源了，目前家里的积蓄花完了，亲戚朋友也都借遍了，不到万不得已，我不会想到筹款这一步，实在是家里没有办法了才申请筹款，希望大家帮帮我们这个家庭，救救我老公吧！\n当时在县人民医院检查怀疑是${patient_disease}，医生就叫我们转到大城市的医院，治疗费用对于我们这个家庭来说简直就是一笔天文数字，但是不管怎么样，我们都不会放弃。他是我们这个家庭的顶梁柱，他不能倒下！ 后期费用我实在无法承担，家中后期也没有生活来源，只能求助于社会爱心人士。` +
                `\n\n求助呼吁：\n` +
                `希望您可以伸出援助之手，为热爱生活之人带去一份生的希望，感谢每一位好心人，感谢你们的善心和恩情，哪怕您只是一次转发，一句加油，都是对我们莫大的支持与帮助，愿善念永存，愿爱心传递！谢谢你们！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助， 谢谢您们！我们急需您们的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!`
        },
        {
            code: `72`,
            pronoun: `他`,
            text: `各位亲友、各界爱心人士：您们好！我的丈夫${patient_name}${age ? '，'+age + '岁' : ''}，我们是来自${address}一个普普通通的家庭！今天在这里向社会各位爱心人士发起求助！跪求大家帮帮我们这个千疮百孔的家庭。` +
                `\n\n患者家庭情况：\n` +
                `这几天我的爱人感觉身体不适，可没想到这一检查我的爱人被确诊为${patient_disease}。医生给我们说了一下后期的治疗方案和花费，面对这后期高昂的治疗费我们实在无力承担，为了给丈夫治疗我们已经把家里所有的积蓄都花了，不光自己的积蓄投进了这个无底洞，我们还向亲戚朋友借了好多的外债，现在是真真切切是感受到了大病给一个家庭带来的不幸是多么的不幸希望社会爱心人士可以帮助我们渡过这个难关！\n我们平时在外地打工，平时收入微薄，我们下有孩子要抚养，上面还有老人要赡养，我们的小孩和老人现在都没有劳动能力，现在能挣钱的唯一的我的丈夫也倒下了，真的感觉就是天塌了下来，丈夫健康的时候就打工维持我们这个家，面对这后期长时间的治疗实在无力承担！爱人还那么年轻，几个孩子还那么小，我和孩子们都需要他。` +
                `\n\n求助呼吁：\n` +
                `万般无奈之下我通过宠物筹写下这封求助，希望大家能伸出援手帮帮我爱人恢复健康！谢谢各位的无私帮助和善良，救助之恩我和家人定铭记于心，今后我和家人有了能力也会尽力帮助需要帮助的人，将大家的爱心继续传递！`
        },

        {
            code: `73`,
            pronoun: `他`,
            text: `星星之火可以燎原，颗颗爱心变希望。 非常抱歉用这种方式打扰到你们！同时也万分感谢好心的你打开我的求助信，感谢你们，希望你们帮帮我，帮帮我老公。` +
                `\n\n患者病情介绍：\n` +
                `我老公叫${patient_name}${age ? '，今年'+age + '岁' : ''}。我们是${address}人，在家打工挣点生活费。家里还有孩子在，一家人过得不富裕但也算幸福。 孩子一天天长大，生活压力越来越大。老公干活越来越多。老公身体不适，到医院检查，发现是${patient_disease}。老公还年轻，肯定是要治疗的。刚开始就吃药，也不能干活，没办法我只能照顾他还有孩子，偶尔打工挣点生活费。想想慢慢会好起来的。老公去复查，发现病情复发更严重了，必须马上治疗。目前在${hospitalName}治疗，很多药都是自费的。大部分是向亲朋好友借来的。${age ? '老公才'+age + '岁，' : ''}孩子也小不能没有爸爸，目前还有希望我们不想放弃。` +
                `\n\n求助呼吁：\n` +
                `家里真的有困难没办法只能找大家帮帮我们。 我知道大家并不富裕，但聚沙成塔，集腋成裘，涓涓细流便可汇成江河。如果大家献出点滴的爱心，对于我们这个家庭来说就是生命的重生，我向大家表示衷心的感谢！ 特此借助【宠物筹】筹款平台向您发起求助，盼望您能伸出援手，助我们一家度过危难，也希望您能帮忙转发扩散这条求助信息，让爱心传递到每个角落。在这里，深深的感谢每一位帮助过我们的亲朋好友，感谢每一位无私转发的爱心人士，让生命得以延续，让爱心充满人间，感激不尽！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!! `
        },

        {
            code: `74`,
            pronoun: `她`,
            text: `非常抱歉打扰到大家！人生有时候真的很脆弱，一场疾病就能将一个人的尊严摧毁，前一刻还在阳光下，下一刻就只能待在昏暗的病房里。` +
                `\n\n患者病情介绍：\n` +
                `今天我为我的妻子患者发起求助，家住${address}。我们是一个普通的家庭，家里上有老下有小，我一直努力打工赚钱，想要改善一家人的生活。可是天有不测风云，人有旦夕祸福,妻子身体突发不适，被确诊为${patient_disease}，得到确诊这个消息，我的大脑顿时变得一片空白，感觉天旋地转。\n我不敢相信，更不愿去相信，但白纸黑字摆在那里，最终也由不得我不相信。我不知道这些天自己是如何度过的，就感觉天塌下来了一样，因为家庭贫困我曾经想到过放弃给妻子治疗，但孩子需要妈妈，父母需要女儿，我需要振作起来，最终选择积极的给妻子接受治疗，实在是承担不起，现在我们全家都感觉手足无措，亲戚朋友们都已经借遍了，还是凑不够医疗费用。` +
                `\n\n求助呼吁：\n` +
                `此刻没有华丽的语言，只有衷心的感谢，只希望大家可以帮忙转发和证实，多一份转发就多一份希望，让更多人可以看到这条求助信息，祝愿全天下的好人都有好报，再次感谢大家！我急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!感谢好心人一生平安！ `
        },

        {
            code: `75`,
            pronoun: `她`,
            text: `大家好，今天发起求助是为了我的老婆${patient_name}${age ? '，今年'+age + '岁' : ''}，我们家在${address}。` +
                `\n\n患者病情介绍：\n` +
                `我们一家日子虽然清贫，但是孩子乖巧懂事，妻子温柔贤惠，也是个幸福之家。但是天有不测风云，一场灾难摧毁了我们幸福的一家。最开始以为妻子就是不舒服，没有在意，去小诊所开了药，后来怎么吃药也没见好，情况反而越来越严重，到医院后经过一系列检查后，妻子被确诊为${patient_disease}，必须尽快住院治疗。这突发的疾病对于我们简直就是晴天霹雳，如果出现了其他问题，就不好说还需要多少钱了，` +
                `\n\n患者家庭情况：\n` +
                `这场疾病带来的不止是痛苦，还有犹如天文数字的治疗费用的压力，这笔费用对我们的家庭来说简直是天文数字，我们平时只能勉强糊口，几乎没有积蓄。家里的孩子还在上学，老人也需要人照顾，现在把亲戚朋友能借的都借了，我真的不知道该怎么办了。` +
                `\n\n求助呼吁：\n` +
                `无奈下，我在宠物筹平台上为妻子发起求助，希望好心人能帮帮我，帮帮我这苦难的家庭。一两毛、一块两块的爱心只要聚集起来，就能给我这个苦难的家庭带来一份希望！好人一生平安，希望人人献出一点爱，人间可以是春天！在此，我先谢谢各位善良的好心人了！希望看到这里的好心人，能帮帮我，救救我们这一家人，谢谢你们！`
        },

        {
            code: `76`,
            pronoun: `她`,
            text: `思量再三，真的无法承担，事与愿违，万般无奈才发起这次筹款，但是我坚信能渡过难关！` +
                `\n\n患者家庭情况：\n` +
                `躺在病床上的是我妻子${patient_name}${age ? '，今年'+age + '岁' : ''}，也是今天的求助人，家住${address}！妻子是一个能持家的人，照顾家庭照顾的很好！我爱她！原以为我们过着平凡而幸福的生活就足够了！然而不幸还是发生了…\n妻子突然发病，诊断为${patient_disease}！现在妻子病情很严重，后续需要继续治疗，面对高昂的治疗费，我一个人真的无能为力，可又力不从心，昂贵的医疗费用支出就像流水一样迅速的压垮了我们！好在家亲朋和同学朋友也在帮忙，我们的压力减轻了不少，感激之情无以为表，而面对后续治疗费用我还是倍感压力，无奈之下才向社会各界求助，希望好心人能帮我们渡过难关，帮忙救救我的妻子，无论如何我们都会坚持下去，所以请大家帮忙一下，转发一下！` +
                `\n\n求助呼吁：\n` +
                `人生在世，最幸福莫不是有人等你回家，我想让她活下去，因为我需要她，我做不到让她这样的离去，无奈写下这封求助信，希望通过宠物筹平台发起筹款。恳请大家救救我的妻子，您们的每一次转发对我来说都是希望。您的每一句鼓励，对我们这个家庭来说，都是战胜病魔的勇气。爱心不分大小，谢谢大家了！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!再次感谢大家！！！`
        },

        {
            code: `77`,
            pronoun: `她`,
            text: `恳请社会各界的爱心人士救救她！我的妻子是${address}人，她是个善良的女人，我们有个可爱的宝宝，也非常乖巧，一家人小日子一直过的很幸福。\n但是天道无常，世事难料，我们根本不会知道意外和明天哪个会先来。` +
                `\n\n患者病情介绍：\n` +
                `妻子身体不适，医生诊断是${patient_disease}，医生说她的病情发展非常迅速，很严重，很可能抢救不过来，要做好心理准备。当我把她推进重症监护室的一刻，眼泪纵横不能自己。不曾想过，以为明天还很多，以后的路还很长，却发现生离死别只在一幕之间，灾难来的如此突然，让人措手不及，难以接受。我告诉自己，即使只有一线希望，也要竭尽全力救治她，而且孩子小不能没有妈！\n进监护室以后，医生们持续抢救，与死神赛跑，凭借着先进的医疗设备和医疗技术，全体医护人员的全力抢救下，终于力挽狂澜，让死神却步，目前妻子已经脱离生命危险，医生说这是个很大的挑战，也是个奇迹。现在所有亲人都在急切地盼望她快快好起来，早日回家！然而ICU费用巨大，这对于一个普通工薪家庭来说，无疑是个沉重的打击，给妻子看病的钱基本上都是向亲朋好友连借带凑来的！现在还在重症监护室继续治疗，面对后续高昂的医疗费用，我们实在无力承担了！` +
                `\n\n求助呼吁：\n` +
                `万般无奈之下只好发起宠物筹进行筹款，真切地希望各位好心人伸出援手，感谢您们的雪中送炭，感谢您的每次转发，这对我的家庭来说都是莫大的帮助，我代表妻子及家人，真心祝愿所有好心人身体健康，万事如意！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },
        {
            code: `78`,
            pronoun: `她`,
            text: `社会各界的爱心人士，你们好。很抱歉以这样的形式让大家认识我，感谢你们打开我的求助信.` +
                `\n\n患者病情介绍：\n` +
                `妻子叫${patient_name}${age ? '，今年'+age + '岁' : ''}，家在${address}。我们一家人虽不是特别富裕，但我们生活也有目标。但天有不测风云，妻子被确诊，患上了${patient_disease}。面对这个检查结果，我们真的蒙了！这么严重的疾病为什么就偏偏落在了我妻子身上。` +
                `\n\n患者家庭情况：\n` +
                `家里动员了所有人，可还是堵不住住院单上的数字。我该去哪儿凑这么多钱？我真的很难过，难过的是自己为什么没能多挣一些钱，为什么看着病床上急需治疗的妻子却想不出办法。万般无奈，通过宠物筹发起了筹款，希望能够借助宠物筹平台给妻子更好的治疗，我身后还有一家老老小小，可我现在连一边都顾不过来......所以在这里恳请各位好心人士伸手相助，哪怕只是一分钱，也可以帮我留住妻子的命！您的每一分爱心、每一次转发我都将心存感恩，永世铭记！恳请好心人伸出援手，多给我们这个家一份希望吧！` +
                `\n\n求助呼吁：\n` +
                `真心的希望大家要保重身体，健康没了什么都是零，最后恳请您在阅读完这篇求助信帮我转发一下，您的每一次转发都是我活下去的希望，感谢所有爱心人士！\n希望大家可以帮忙转发和证实，多一份转发就多一份希望，让更多人可以看到这条求助信息，祝愿全天下的好人都有好报，再次感谢大家！我急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!感谢好心人一生平安！!`
        },

        {
            code: `79`,
            pronoun: `她`,
            text: `很抱歉，打扰大家了，麻烦大家看到信息的帮我转发一下为挽救我妻子发的求助信息，您的举手之劳能为我妻子带来生的希望，麻烦认识我的亲戚朋友实名验证一下情况属实，跪谢。` +
                `\n\n患者病情介绍：\n` +
                `大家好，我的妻子叫${patient_name}${age ? '，今年'+age + '岁' : ''}，家住${address}。虽然家庭不富裕，但这其中妻子承受了很多的艰辛，我都一直看在眼里，记在心里。原本以为我有了经济能力，妻子就可以轻松一点了，但是事与愿违，疾病的到来让我们的家庭陷入深深的困境！\n妻子突然身体不适，我带着妻子到医院做了各项检查，被确诊为${patient_disease}，我们家人听到这个结果后万分伤心，经过家人们一起商量，就算砸锅卖铁也要进行治疗，如果不治疗这将是我一生的遗憾。医生说要想治疗，这个高额的治疗费真的难倒了我们！` +
                `\n\n求助呼吁：\n` +
                `思量再三，万般无奈下，我发起这次求助。恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!我们急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!非常感谢你们！`
        },

        {
            code: `80`,
            pronoun: `她`,
            text: `星星之火可以燎原，颗颗爱心变希望。 非常抱歉用这种方式打扰到你们！同时也万分感谢好心的你打开我的求助信，感谢你们，希望你们帮帮我，帮帮我妻子。` +
                `\n\n患者家庭情况：\n` +
                `我妻子叫${patient_name}${age ? '，今年'+age + '岁' : ''}，我们是${address}人，一家人过得不富裕但也算幸福。 妻子生病的日子，看着她每天被病痛折磨，我恨不能自己替她承受，可是我没用，现在连她的救命钱都无能为力。到现在，我每天除了照顾她，就是想办法筹钱。我实在没有办法才来这里请求各位兄弟姐妹的帮助！` +
                `\n\n求助呼吁：\n` +
                `目前还有希望我们不想放弃。 家里真的有困难没办法只能找大家帮帮我们。 我知道大家并不富裕，但聚沙成塔，集腋成裘，涓涓细流便可汇成江河。如果大家献出点滴的爱心，对于我们这个家庭来说就是生命的重生，我向大家表示衷心的感谢！\n特此借助【宠物筹】筹款平台向您发起求助，盼望您能伸出援手，助我们一家度过危难，也希望您能帮忙转发扩散这条求助信息，让爱心传递到每个角落。在这里，深深的感谢每一位帮助过我们的亲朋好友，感谢每一位无私转发的爱心人士，让生命得以延续，让爱心充满人间，感激不尽！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },

        {
            code: `81`,
            pronoun: `她`,
            text: `您这次善心的转发朋友圈就可能挽救一个鲜活的生命，感谢好心的您在百忙之中打开我们这封求救信，无论你们帮助与否都是我们的恩人，我们全家都会将这份救命之恩铭记在心里，将来尽我们的全部感恩所有好心人的帮助与付出。` +
                `\n\n患者病情介绍：\n` +
                `医院的一张确诊书，不仅是敲碎了个人对生命的憧憬，更是打碎了整个家庭幸福的现状.妻子${patient_name}${age ? '，在'+age + '岁的年纪' : ''}，被告知患有${patient_disease}，家住${address}，妻子身体突发不适，医生告知需要做手术，我们得知这个消息后真的犹如五雷轰顶，巨大的医疗费用实在是无力承担，每次看到躺在病床上的妻子，就在想为什么灾难降临在我妻子的身上，我不想就这样让我的妻子离开这个的家庭，离开这个美好的世界，我们整个天空现在都成了一片黑暗，我现在把所有的希望放在这里。` +
                `\n\n求助呼吁：\n` +
                `希望可以通过宠物筹这个平台为妻子筹集一些治疗的救治费用。实在是没办法中的办法了，我知道大家并不富裕，但聚沙成塔，涓涓细流便可汇成大海，一两毛、一块两块的爱心只要聚集起来，就能给我这个苦难的家庭带来一份希望！\n恳求各位爱心人士帮帮忙，多多转发和扩散出去！多一份转发就多一份希望，让更多人可以看到这条求助信息，祝愿全天下的好人都有好报，再次感谢大家！我急需您的帮助！望好心人士伸手相助，感谢好心人一生平安！也真心的希望大家要保重身体，健康没了什么都是零，最后祝愿大家健康平安！我在这里谢谢谢谢大家了！`
        },
    ];
    const parentTemplates = [{
            code: `82`,
            pronoun: `他`,
            text: `各位爱心人士你们好！感谢您百忙之中抽出时间打开我的求助信！ 很无奈在这里通过这样的方式向大家发起爱心求助，此时此刻面对着眼下的考验，我真的不知道该怎么办了。` +
                `\n\n患者病情介绍：\n` +
                `我家住${address}，我父亲${patient_name}${age ? '，今年'+age + '岁' : ''}，被诊断为：${patient_disease}。 我们是普通家庭，父亲这次入院手术，都是靠我们亲戚朋友拼拼凑凑，作为父母，总是不愿意自己的子女被拖累，作为子女，又怎么忍心父母被折磨而放任不管，就算再艰难也拼命咬牙坚持……\n然而每日增长的医疗费用，我们实在是难以支撑。 医院已经给出确切的治疗方案，后续需要进行长期的住院观察治疗，我们已经拿不出更多的钱来维持后期高额的医疗费用，各位善良好心的人们，若不是已山穷水尽，若不是正尴尬拮据，也不会走筹款这一步，恳请你们能够伸出援手帮我们一把，让倍受折磨的父亲能接受治疗！我苦命的父亲啊，不管怎样只要有一丝希望，我们绝不放弃！也请您坚持下去，做我们一直的坚强斗士！` +
                `\n\n求助呼吁：\n` +
                `如果有幸此次得到帮助，我们将没齿不忘各位爱心人士的大恩大德！我们将义无反顾、坚持到底，我们相信只要坚持就有希望，只要人人奉献出一点爱，胜利终将属于我们！谢谢您的关心和帮助，哪怕只是一次转发，感恩，好人一生平安！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!谢谢！ 此致敬礼！`
        },

        {
            code: `83`,
            pronoun: `他`,
            text: `树欲静而风不止，子欲养而亲不待，如果不是万不得已，真不愿在这打扰大家。` +
                `\n\n患者病情介绍：\n` +
                `我家住${address}，我的父亲${patient_name}${age ? '，今年'+age + '岁' : ''}，因患${patient_disease}病情严重，现在医院治疗，为了给父亲争取一线生机，万般无奈在此求助。\n最近，父亲出现身体不适，我们到医院治疗，被确诊为${patient_disease}。由于医院条件有限，大夫说父亲的病情比较严重，后续治疗周期长且费用巨大。` +
                `\n\n患者家庭情况：\n` +
                `我们家都是普通的家庭，收入非常有限。谁知道父亲突然得了这个病。这让我们本就不富裕的家庭更是雪上加霜！这半年来为了给父亲看病，不仅花光了家里本就不多的积蓄，\n现在后续的治疗费成了家里的大问题。父亲操劳一生养育我们不容易，我们就算再困难也要给父亲看病！` +
                `\n\n求助呼吁：\n` +
                `在没有办法的情况下，我们才借助宠物筹平台，想通过宠物筹，让更多的爱心人士看到我们，帮帮我们，谢谢大家,那怕您的一次转发，也是让我们坚持下去的理由，谢谢大家了！无论结果如何，我们以后一定要回报社会，将大家的爱心传递下去！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },
        {
            code: `84`,
            pronoun: `他`,
            text: `紧急求助！——父爱无疆！生我养我的父亲突患${patient_disease}！恳请大家伸出援手，救救我父亲的生命！ 在很多时候，人们总是微小如砂砾，上天并不能看到每个人所面临的痛苦，而阳光也有洒不到的角落。世间的温暖更多的是来自相互的给予。当您在阅读这封信时，也许我们是认识的，也许您我只是相隔数万里的陌生人。但我依然希望您能继续阅读下去，谢谢您！` +
                `\n\n患者病情介绍：\n` +
                `尊敬的各位好心⼈，您们好，${patient_name}是我的父亲${age ? '，今年'+age + '岁' : ''}。我们的家庭一直很幸福，虽然过着粗茶淡饭的日子，但是我们很满足。但是一场意外打破了这个平静，父亲突然身体不适，经过反复检查被确诊为${patient_disease}。\n我们听到这个消息的时候，犹如晴天霹雳，我们一大家子一时间缓不过气来，我害怕极了，失声痛哭，老天怎么能让我父亲得了这个病，看到父亲在治疗时疼得直咬牙，我的心就和插了好几把⼑⼦一样，为什么要让他承受这些！` +
                `\n\n患者家庭情况：\n` +
                `除了父亲承受的病痛，还有这高额的医疗费用，前⾯的治疗已经把家里的所有积蓄花光了，我们家就是普普通通的家庭，后续的医疗费对我们家的负担实在是有点太难了，万般⽆奈只好恳求社会各界的善良好⼼心人救救我的父亲！` +
                `\n\n求助呼吁：\n` +
                `父亲是一种岁月，他担负着最多的痛苦，背负着最多的压力，咽下最多的泪水，仍以爱，以温情，以慈悲，以善良，以微笑， 对着人生，对着我们！没有父亲，生命将是一团漆黑；没有父亲，世界将失去温暖！希望大家能够帮帮我的父亲，让他能够顺利治疗，能够早日康复，恩情铭记于心，日后定当报答！希望各位社会爱⼼人士帮我们家渡过难关，创造生命奇迹，哪怕是一分钱，一点祝福，也能给我们带来莫大的希望和鼓励，我和我们全家叩首致谢!好人一生平安!`
        },
        {
            code: `85`,
            pronoun: `他`,
            text: `感谢大家能够在百忙之中抽出时间来打开我们紧急求助信！` +
                `\n\n患者病情介绍：\n` +
                `大家好，${patient_name}是我父亲${age ? '，今年'+age + '岁' : ''}，我们住在${address}！因为父亲病情严重，不得已向社会发起求助！\n天有不测风云，父亲突然身体不适，经过医生检查，最终被确诊，父亲不幸被诊断为：${patient_disease} ，事情发生的突然，我们都已经不知道该如何是好，现在父亲在医院一直没有好转,我们伤心不已，压力很大的还有后期高昂的治疗费用，` +
                `\n\n患者家庭情况：\n` +
                `家里已经竭尽所能，可无论有多难，我们也要极力挽救父亲，我们全家也在四处筹借治疗费，可还是不够，只能但凡有一点办法也不愿去麻烦社会和亲人好友……迫不得已无奈只能求助，请求社会各界爱心人士帮帮我们的父亲，父亲一辈子务农养育我们长大，恳请不要让我们心目中的巨人倒下……` +
                `\n\n求助呼吁：\n` +
                `希望通过社会的力量，呼吁各界爱心人士帮帮我们，挽救我父亲度过这个难关！谢谢大家，哪怕是您的一次转发，一分帮助，都是我们治疗下去的希望，我们感恩敬上。我们需要您的帮助，您每一句安慰的话语，每一次的捐款，每一次的转发，都是力量的积蓄，这种力量能为我们汇聚出一条康复之路。请大家可以帮帮我们渡过难关，大家的恩情我们定会铭记心。谢谢您们，好人一生平安！我们急需您的帮助！接力点亮生命的希望之光!谢谢各位亲朋好友的帮助，在这里谢谢大家！`
        },
        {
            code: `86`,
            pronoun: `他`,
            text: `尊敬的爱心人士您好！ 很抱歉我以着这样的方式出现在大家的面前，家住${address}，这一次也是万般无奈！为我的父亲求助大家！` +
                `\n\n患者病情介绍：\n` +
                `我的父亲${patient_name}${age ? '，今年'+age + '岁' : ''}，目前正在${hospitalName}治疗，在医院会诊完，医生说情况比较严重，后期花费较大，让我们做好准备，现在除去医保报销完，现在父亲的情况还是比较严重，确实不知道该怎么办了。` +
                `\n\n患者家庭情况：\n` +
                `子欲养而亲不待，父亲养我长大确实不容易，现在看着他被疾病折磨，我却不知道该怎么办！万般无奈！通过宠物筹平台求助大家！ 说起家境，父亲身体一直不好，普通家庭，确实也没有办法了！才通过宠物筹平台求助大家！希望大家可以帮助我们度过难关！ 但是我时常在想，是不是普通的家庭面对疾病只能举手投降……毫无对策！	` +
                `\n\n求助呼吁：\n` +
                `辗转得知当今社会有宠物筹大病救助筹款这个平台，怀着一颗期盼的心，恳求得到社会各界好心人的帮助！\n 哪怕是一元，两元都是满满的爱！一点一滴，聚沙成塔！赠人玫瑰，手留余香！我真诚的希望得到大家的帮助！ 再次感谢大家，我一生最重要的决定是保护家庭、关爱家人，我有缘能尽力之所怀服务社会。能和每一位有心人一起弘扬、对这福份感刻至深。 最后，祝愿天下好人一生平安健康！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要！`
        },
        {
            code: `87`,
            pronoun: `他`,
            text: `思量再三，真的无法承担，事与愿违，万般无奈才发起这次筹款，但是我坚信能渡过难关！` +
                `\n\n患者病情介绍：\n` +
                `躺在病床上的是我父亲${patient_name}${age ? '，今年'+age + '岁' : ''}，也是今天的求助人，我们家住${address}！父亲是一个平凡但又伟大的人，从小到大，总是把最好东西都给予我！我爱他！原以为我长大了，他就可以享福了！父亲突然发病，诊断为${patient_disease} ！现在父亲病情很严重，后续还需要继续治疗，面对高昂的治疗费，我一个人真的无能为力！` +
                `\n\n患者家庭情况：\n` +
                `百善孝为先，可又力不从心，昂贵的医疗费用支出就像流水一样迅速的压垮了我们！好在亲朋好友和同学朋友也在帮忙，我们的压力减轻了不少，感激之情无以为表，而面对后续治疗费用我还是倍感压力，无奈之下才向社会各界求助，希望好心人能帮我们渡过难关，帮忙救救我的父亲，无论如何我们都会坚持下去，所以请大家帮忙一下，转发一下！` +
                `\n\n求助呼吁：\n` +
                `人生在世，最幸福莫不是有人等你回家，叫他一声爸！我想他活下去因为我还没有孝敬他，我需要他，我做不到他这样的离去，无奈写下这封求助信，希望通过宠物筹平台发起筹款。恳请大家救救我，您们的每一次转发对我来说都是希望。您的每一句鼓励，对我们这个家庭来说，都是战胜病魔的勇气。\n爱心不分大小，谢谢大家了！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },
        {
            code: `88`,
            pronoun: `他`,
            text: `人生百态，或喜或悲，在不同的人生阶段会有不一样的经历，其实这些年的我经历很多，非常难，很多人也给了我帮助！真的非常感激他们，但是现在还是遇到了无法逾越的鸿沟，实在没办法面对现在所患有的疾病，请求大家的帮助，发起宠物筹来求助 各位好心人，请大家帮帮我们！` +
                `\n\n患者病情介绍：\n` +
                `各位爱心人士，你们好！很感谢您打开我的求助信！请您救救我的父亲${patient_name}，我们是${address}人。 天有不测风云，没想到重大的疾病来的这么突然，对于一个家庭来说就是天崩地裂，父亲突然身体感到不适，我就带父亲到${hospitalName}进行了检查, 诊断为${patient_disease} ！` +
                `\n\n患者家庭情况：\n` +
                `我们的家庭就是普普通通的家庭，一年的收入除了年吃与年用的手中根本没有多少积蓄，日子虽然不富裕但是有家人在一起还是很幸福的，却不曾想突然发生这样的事情。\n现在的治疗费用都是亲朋好友帮助筹借到的，我们本不想麻烦大家，我们现在连治疗的费用都没有，更可怕的是医院通知我们后续需要长时间的治疗，现在已经是我们家庭的极限了，更是我们家庭无法想象与承受的，对于一个这样的家庭来说整个治疗费用就是天文数字，所以想借此平台想让大家伸出援助之手，希望爱心人士帮助我们筹够治疗费用，帮我们度过这个难关。` +
                `\n\n求助呼吁：\n` +
                `在这里我谢谢大家！好人一生平安！您的关心和帮助，我和家人都没齿难忘，您的每一次转发都是无比温暖，您的每一份爱心都能给我们家带来希望，谢谢你们，万分感谢大家的援助，我们铭记于心，我也把大家的名字都记录了下来，在这里我们家人给大家鞠躬了！日后我们度过难关我们必将回报！我们急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!`
        },

        {
            code: `89`,
            pronoun: `他`,
            text: `即使你不是医生也能挽救性命，星星之火可以燎原，颗颗爱心变希望。意外和明天谁都无法预料，更不知道命运怎么安排。` +
                `\n\n患者病情介绍：\n` +
                `各位爱心人士大家好：非常感谢大家在百忙之中打开我的求助信，患者是我父亲${patient_name}${age ? '，今年'+age + '岁' : ''}，家住${address}。父亲是一个家庭的顶梁柱，是我们家人的依靠，他总是为我们遮风挡雨，无怨无悔的为家庭付出。 一向身体很好的父亲，一直都是为了家庭，辛辛苦苦付出，无非就是想给我们更好的生活。\n但是天有不测风云，父亲因为身体不舒服，就去了${hospitalName}检查，结果不幸被诊断${patient_disease}，听到这个消息让我们整个家庭都无法接受，平时身体好好的父亲怎么会一下子病的那么严重。 我们只能按医生的治疗方法先进行治疗，从看病到现在，后面的费用更是无法估算。` +
                `\n\n求助呼吁：\n` +
                `这笔高额的治疗费用对于我们本就不富裕的家庭来说无疑是雪上加霜。 不得已，在经过家人商量后，无奈求助于社会爱心人士，希望得到大家的帮助，救救我的父亲，让他回归正常生活，您的每一份爱心都是对我们的家庭关心与祝福，哪怕您的一次转发都万分感谢。如有打扰，还请谅解。谢谢！\n恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!我们急需您的帮助！多一份转发就多一份希望，接力点亮生命的希望之光!`
        },

        {
            code: `90`,
            pronoun: `他`,
            text: `感谢大家能够在百忙之中抽出时间来打开我们紧急求助信！` +
                `\n\n患者病情介绍：\n` +
                `亲爱的爱心人士，你们好！父亲叫${patient_name}${age ? '，今年'+age + '岁' : ''}，家住${address}。我们一家是实实在在的人家，万事都积极去面对，但天意弄人，父亲突然身体不能动弹，我们立刻带父亲去医院检查.检查报告犹如晴天霹雳，震惊了我们。医生确诊为${patient_disease}。\n面对突如其来的命运的严冬与现实的冷酷，我真不知道父亲能不能承受得住。但我知道怨天尤人解决不了任何问题，只有我们坚强起来才能支撑起这个家，由于不敢相信这是事实，希望是个误诊，花光了我们所有的积蓄，我能体会到父亲化疗后所带来的痛苦，但他一直很坚强配合好医生做治疗。` +
                `\n\n患者家庭情况：\n` +
                `父亲一生为家操劳，不辞辛苦，还没机会享受生活就患上${patient_disease}，我还没好好尽孝呢。父亲曾几度想放弃治疗，一直说对不起，让我受苦了，我说没事，现在医疗技术这么先进，你一定会好起来的。父爱如山啊。百善孝为先，可又力不从心，昂贵的医疗费用支出就像流水一样迅速的压垮了我们。好在家人和同学朋友也在帮忙，我们的压力减轻了不少，感激之情无以为表，而面对这个治疗费用我们还是倍感压力，无奈之下才向社会各界求助。` +
                `\n\n求助呼吁：\n` +
                `希望好心人能帮我们渡过难关，帮忙救救我的父亲，无论如何我们都会坚持下去，哪怕千辛万苦也绝不放弃，哪怕只有一线生机我们也要与命运抗争到底！至少不能让父亲如此绝望地离开，我还想好好尽尽孝心！\n恳请各位好心人帮帮我们，您的帮助、转发、鼓励，对我们来说都是莫大的力量，哪怕只是一次转发，感恩，好人一生平安！多一份爱心就多一份希望！谢谢大家了。恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },
        {
            code: `91`,
            pronoun: `他`,
            text: `人生百态，或喜或悲，在不同的人生阶段会有不一样的经历，其实这些年的我经历很多，非常难，很多人也给了我帮助！真的非常感激他们，但是现在还是遇到了无法逾越的鸿沟，实在没办法面现在所患有的疾病，请求大家的帮助，发起宠物筹来求助 各位好心人，请大家帮帮我们！` +
                `\n\n患者病情介绍：\n` +
                `各位爱心人士，你们好！很感谢您打开我的求助信！各位爱心人士，您们好，感谢您们打开我的求助信，我想请各位好心人帮帮我的父亲${patient_name}${age ? '，今年'+age + '岁' : ''}，我们是${address}人，天有不测风云，我父亲身体突然不适，于是和他一起去医院做检查，看着躺在病床上面色蜡黄的父亲，我多希望自己能帮他承担一分病痛也好！` +
                `\n\n患者家庭情况：\n` +
                `从确诊到现在，后续治疗费用是个无底洞，面对病魔我感觉到了从未有过的压力，只能竭尽所能的治疗，希望他能尽快好起来。\n医院高昂的治疗费用，对于我们普通家庭来说是个天文数字，我们实在承受不起，希望大家帮帮我们！` +
                `\n\n求助呼吁：\n` +
                `平日里我也一直在尽自己所能帮助他人，我们不愿意麻烦别人，此次也是万般无奈才向各位好心人求助，恳求大家帮帮我，救救我的父亲，您的关心和帮助，我和家人都没齿难忘，您的每一次转发都是无比温暖，您的每一份爱心都能给我们家带来生的希望，谢谢你们，好人一生健康幸福平安！我们急需您的帮助！`
        },
        {
            code: `92`,
            pronoun: `他`,
            text: `感谢大家能够在百忙之中抽出时间来打开我们紧急求助信！` +
                `\n\n患者病情介绍：\n` +
                `社会各界的爱心人士，你们好。父亲叫${patient_name}${age ? '，今年'+age + '岁' : ''}，家在${address}。我们一家人虽不是特别富裕，但长辈精神头好，我们生活也有目标。但天有不测风云，父亲被确诊为${patient_disease}，面对这个检查结果，我们真的蒙了！\n这么严重的疾病为什么就偏偏落在了我父亲身上，他已经吃了那么多苦，老了老了，为什么还要让他经历这些？家里动员了所有人，可还是堵不住住院单上的数字。我该去哪儿凑这么多钱？我真的很难过，难过的是自己为什么没能多挣一些钱，为什么看着病床上急需治疗的父亲却想不出办法。身后还有一家老老小小，可我现在连一边都顾不过来......` +
                `\n\n求助呼吁：\n` +
                `特此借助【宠物筹】筹款平台向您发起求助，盼望您能伸出援手，助我们一家度过危难，也希望您能帮忙转发扩散这条求助信息，让爱心传递到每个角落。在这里，深深的感谢每一位帮助过我们的亲朋好友，感谢每一位无私转发的爱心人士，让生命得以延续，让爱心充满人间，感激不尽！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },
        {
            code: `93`,
            pronoun: `他`,
            text: `其实在很多时候，人们总是微小如砂砾，上天并不能看到每个人所面临的痛苦，而阳光也有洒不到的角落。世间的温暖更多的是来自相互的给予。当您在阅读这封求助信时，也许我们是认识的，也许您我只是相隔数万里的陌生人。但我希望您依然继续阅读下去，谢谢您！` +
                `\n\n患者病情介绍：\n` +
                `尊敬的亲朋好友及各界爱心人士： 躺在病床上的是我父亲${patient_name}${age ? '，今年'+age + '岁' : ''}，我们老家住${address}。一场突如其来的疾病席卷了我们家，父亲病情也没有好转，目前被诊断为: ${patient_disease} ，医生说父亲病情危重，随时会有生命危险，还需要住院很久，不应该在这个年纪倒下，虽然现在生了那么严重的病，但我们一家人都坚信他会好起来。` +
                `\n\n求助呼吁：\n` +
                `面对后续治疗费用我们压力实在太大，无奈之下只能找到宠物筹平台，想借此寻求到一丝希望。 绿叶需要阳光的照耀，它从来不拒绝阳光。我们需要爱和爱心，也不会拒绝爱和爱心。\n我们需要您的帮助，您每一句安慰的话语，每一次的捐款，每一次的转发，都是力量的积蓄，这种力量能为我们汇聚出一条康复之路。请大家可以帮帮我们渡过难关，大家的恩情我们定会铭记心。谢谢您们，好人一生平安！我们急需您的帮助！接力点亮生命的希望之光!`
        },

        {
            code: `94`,
            pronoun: `他`,
            text: `各位爱心人士，你们好，感谢你们打开我的求助信，我想请各位好心人救救我的父亲${patient_name}！` +
                `\n\n患者病情介绍：\n` +
                `${age ? '我父亲他今年'+age + '岁' : ''}我们是${address}人，原本我们家一直幸福快乐，其乐融融，谁知天有不测风云，父亲身体突发不适，没想到我父亲居然被确诊为${patient_disease}。\n医生说很难治疗，但是我不想放弃， 全靠之前挣的点钱维持基本生活，医院的治疗费用，对于我们普通家庭来说，简直是一个天文数字，看着躺在病床上面色蜡黄的父亲，我多希望自己能帮他承担一分病痛也好！` +
                `\n\n患者家庭情况：\n` +
                `从确诊到现在，面对病魔我感觉到了从未有过的压力，只能竭尽所能的治疗，希望他能尽快好起来。 平日里我也一直在尽自己所能帮助他人，我们不愿意麻烦别人，此次也是万般无奈才向各位好心人求助，恳求大家帮帮我，救救我的父亲，您的关心和帮助，我和家人都没齿难忘，您的每一次转发都是无比温暖，您的每一份爱心都能给我们家带来生的希望，谢谢你们，好人一生健康幸福平安！我们急需您的帮助！` +
                `\n\n求助呼吁：\n` +
                `望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },

        {
            code: `95`,
            pronoun: `他`,
            text: `各位好心人： 你们好，来自于${address}。很感激你们能打开这封求助信，今天万般无奈的写下这封是因为我父亲的病情不得已而为之……	` +
                `\n\n患者病情介绍：\n` +
                `我的父亲叫${patient_name}，他是一个地道的农民${age ? '，今年'+age + '岁' : ''}。这么多年，他就像一座大山为我们一家人遮风挡雨，凭借他粗糙的双手养着我们一家人。生活虽然清贫，但本来想日子平平安安过下去也就算了，然而，但因为家里的生活非常贫困，“小病挨，大病拖”一直瞒着我们没去医院检查。实在疼痛难忍才把实情跟我们坦白。\n于是，经检查，医生初步诊断为：${patient_disease}！ 医生跟我们说，父亲的病情严重，建议转到上一级医院继续治疗。` +
                `\n\n患者家庭情况：\n` +
                `治疗费用对于我们这个家庭来说无疑是无法承担的！ 这些年家庭压力真的很大。我的母亲身体一直都不好，这几年因为身体的原因反复住院，做了好几次手术，很多费用都是跟亲戚朋友东拼西凑的，直到现在也没有还清，家里的生活已经是一贫如洗了！ 但是现在父亲不幸患病，命运再一次再一次给这个家蒙上了雪霜，面对巨额的医疗费，我们一筹莫展，只想尽快筹钱为父亲继续治疗。	` +
                `\n\n求助呼吁：\n` +
                `万般无奈之下，只能借助宠物筹平台求助于各界爱心人士。还望各位好心人能够施以援手，让我们家度过这次难关。我们会永远怀着感恩的心，感激各位能伸出了援手，帮助了这个家庭。或许，你不能经济上的支持，但我们仍然感谢你有帮助之心，默默为我们转发，让更多的人看到，敬谢！`
        },

        {
            code: `96`,
            pronoun: `她`,
            text: `在生命的危机时刻，那些素未谋面的爱心与善意如同骄阳似火，总能温暖我的心灵！感谢大家在百忙之中抽出时间来阅读这一封求助信，感谢大家！` +
                `\n\n患者病情介绍：\n` +
                `各位好心人，非常感谢你们打开我的求助信，恳请大家帮帮我的${age ? age + '岁' : ''}的母亲${patient_name}，渡过难关！我们家住${address}，一向身体都很好的母亲，在今年不幸被确诊为${patient_disease}，这个结果犹如晴天霹雳一般劈在我们的心头！\n本以为手术后就会逐渐康复，却没有想到母亲的治疗一步接一步，恶运就这样降临在了母亲身上！后来我们才知道这个病要长期治疗，手术后，要化疗再放疗，现在母亲还要治疗很长时间，这对我们家来说实在压力太大，亲戚朋友虽然都在尽力帮忙但还是差很多，不是因为重病也不会进入医院，我们做子女的真恨自己能力不够，只想家人身体健康，就算前方的路多么曲折我也要尽我最大的能力，万般无奈下才发起求助。` +
                `\n\n求助呼吁：\n` +
                `星星之火可以燎原，颗颗爱心变希望。我急需您的帮助！望好心人士伸手相助，接力点亮生命的希望之光！希望诸位好心人士，可以奉献些爱心帮帮我患重病的母亲，希望大家可以帮忙转发和证实，多一份转发就多一份希望，让更多人可以看到这条求助信息，祝愿全天下的好人都有好报，再次感谢大家。`
        },

        {
            code: `97`,
            pronoun: `她`,
            text: `父母在，人生尚有来处！ 父母去，人生只剩归途！` +
                `\n\n患者病情介绍：\n` +
                `社会上的爱心人士大家好，患病是我母亲${patient_name}，我们是${address}人${age ? '，今年'+age + '岁' : ''}。母亲一生都不容易，为了我们一生操劳！原本我以为就算再苦再累，只要家里人身体健康，无病无灾！我也愿意吃白粥过生活，然而不幸还是发生了！母亲突然在家身体不适，我们马上把她送${hospitalName}，后来医院说情况严重，结果诊断为${patient_disease}，面对这高昂的治疗费，我真的无能为力！！ 子欲养而亲不在的痛苦一次次压迫我的心脏。` +
                `\n\n求助呼吁：\n` +
                `无奈以这样的方式打扰各位；亲朋好友们也都在帮忙想办法，奈何大家都不富裕。我知道大家的每一分钱都来之不易，也知道任何言语上的感激都很苍白。感恩所有的好心人，希望你们能一生平安、幸福！感恩！我们急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大帮助！谢谢大家，好人一生平安！`
        },
        {
            code: `98`,
            pronoun: `她`,
            text: `星星之火可以燎原，颗颗爱心变希望。 非常抱歉用这种方式打扰到你们！同时也万分感谢好心的你打开我的求助信，感谢你们，希望你们帮帮我，帮帮我母亲。` +
                `\n\n患者病情介绍：\n` +
                `我母亲叫${patient_name}${age ? '，今年'+age + '岁' : ''}。我们是${address}人，一家人过得不富裕但也算幸福。母亲因身体不适到${hospitalName}检查，被确诊${patient_disease}。刚听到这个消息，总是觉得自己听错了，但真实的检测报告拿在手里又不由得你不相信。医生说母亲的病很严重，让我们做好心理准备，但是还有希望我们不想放弃。这么高昂的费用真不知道该怎么办才好。 家里真的有困难没办法只能找大家帮帮我们。` +
                `\n\n求助呼吁：\n` +
                `我知道大家并不富裕，但聚沙成塔，集腋成裘，涓涓细流便可汇成江河。如果大家献出点滴的爱心，对于我们这个家庭来说就是生命的重生，我向大家表示衷心的感谢！\n特此借助【宠物筹】筹款平台向您发起求助，盼望您能伸出援手，助我们一家度过危难，也希望您能帮忙转发扩散这条求助信息，让爱心传递到每个角落。在这里，深深的感谢每一位帮助过我们的亲朋好友，感谢每一位无私转发的爱心人士，让生命得以延续，让爱心充满人间，感激不尽！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },

        {
            code: `99`,
            pronoun: `她`,
            text: `在生命的危机时刻，那些素未谋面的爱心与善意如同骄阳似火，总能温暖我的心灵！感谢大家在百忙之中抽出时间来阅读这一封求助信，感谢大家！万不得已，通过宠物筹来寻求大家的帮助！` +
                `\n\n患者病情介绍：\n` +
                `生病的是我的母亲${patient_name}${age ? '，今年'+age + '岁' : ''}。我们来自${address}。母亲发现了自己不舒服,于是就到医院做了检查！入院检查后确诊为${patient_disease}，可是我们是普通的家庭，根本负担不起这样高的治疗费，家里的主要经济收入是靠母亲。` +
                `\n\n患者家庭情况：\n` +
                `现在前期治疗已经花光了家里所有积蓄，在我们最需要钱治疗的时候家里却拿不出分毫了！高昂的治疗费用让我无法喘息，母亲为了拉扯我长大成人已经吃了很多苦，不想在享福的年纪却被病痛折磨！现在我的家庭现在实在拿不出如此高昂的医疗费用。但是我也不甘心因为没钱治疗而延误病情治疗！！！ 一直都是母亲为这个家撑起一片天，现在母亲身在危难。我也会尽我最大的能力来和他一起度过难关！` +
                `\n\n求助呼吁：\n` +
                `希望大家可以帮帮我们，帮帮我们这个风雨飘摇的家！真的不想因为没钱治疗而耽误病情！实在是万不得已恳请大家帮帮我！恳请大家帮我多多转发！谢谢善心人！您的每一次转发都是我生的希望！感恩您的帮助！真心感谢各位好心人！真的谢谢你帮助！`
        },
        {
            code: `100`,
            pronoun: `她`,
            text: `各位爱心人士你们好！感谢您百忙之中抽出时间打开我的求助信！ 很无奈在这里通过这样的方式向大家发起爱心求助，此时此刻面对着眼下的考验，我真的不知道该怎么办了。` +
                `\n\n患者家庭情况：\n` +
                `家住${address}。我母亲${patient_name}${age ? '，今年'+age + '岁' : ''}。诊断为：${patient_disease}。 我们是普通家庭，母亲这次入院，都是靠我们亲戚朋友拼拼凑凑，作为父母，总是不愿意自己的子女被拖累，作为子女，又怎么忍心父母被折磨而放任不管，就算再艰难也拼命咬牙坚持……\n然而每日增长的医疗费用，我们实在是难以支撑。 医院已经给出确切的治疗方案，后续需要进行长期的住院观察治疗，我们已经拿不出更多的钱来维持后期高额的医疗费用，各位善良好心的人们，若不是已山穷水尽，若不是正尴尬拮据，也不会走筹款这一步，恳请你们能够伸出援手帮我们一把，让倍受折磨的母亲能接受治疗！我苦命的母亲啊，不管怎样只要有一丝希望，我们绝不放弃！也请您坚持下去，做我们一直的坚强斗士！` +
                `\n\n求助呼吁：\n` +
                `如果有幸此次得到帮助，我们将没齿不忘各位爱心人士的大恩大德！我们将义无反顾、坚持到底，我们相信只要坚持就有希望，只要人人奉献出一点爱，胜利终将属于我们！谢谢您的关心和帮助，哪怕只是一次转发，感恩，好人一生平安！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!谢谢！ 此致敬礼！`
        },
        {
            code: `101`,
            pronoun: `她`,
            text: `疾病的降临总是悄无声息，躲不过也逃不开，能做的只有迎难而上，感谢大家抽出时间来阅读这一封求助信，给我们这个家一丝丝的温暖!非常抱歉用这样的方式打扰到您！` +
                `\n\n患者家庭情况：\n` +
                `患者叫${patient_name}，是我的母亲${age ? '，今年'+age + '岁' : ''}，在${hospitalName}被诊断出${patient_disease}的疾病。我们家在${address}，我们是勤劳朴实善良的老百姓，虽然日子苦点，但是是幸福的快乐的，因为有很多人关心着我们。\n家境贫寒，突遇重病，曾几何时，我也和您一样从不相信疾病会与亲人对号入座，可是上天就是如此残忍，突如其来的一场大病压的我们全家连气都喘不过来！病倒一人，目前家庭状况已经不能维持治疗，医院里花钱如流水，本不想麻烦大家，我们就是普通家庭，父母也都是老实本分的老百姓，医院也一直催缴治疗费用 亲戚朋友也都在尽心尽力的帮我们！可是治疗花费还是有很大的缺口！` +
                `\n\n求助呼吁：\n` +
                `实在没有办法了只能想到求助宠物筹平台，恳请好心人帮帮我渡过难关。帮帮我的母亲！人间有大爱，感谢您的帮助，感谢您带给我们的希望，妈妈好起来吧，我们都需要您！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们都是莫大的帮助！\n在这里恳请各位好心人帮帮我们！哪怕只是转发和留言，对我都是莫大的支持和帮助！谢谢每一位伸出援手的好心人，愿善念永存，愿爱心传递！`
        },

        {
            code: `102`,
            pronoun: `她`,
            text: `其实在很多时候，人们总是微小如砂砾，上天并不能看到每个人所面临的痛苦，而阳光也有洒不到的角落。世间的温暖更多的是来自相互的给予。当您在阅读这封信时，也许我们是认识的，也许您我只是相隔数万里的陌生人。但我希望您依然继续阅读下去，谢谢您！` +
                `\n\n患者病情介绍：\n` +
                `各位爱心人士大家好!我妈妈${patient_name}${age ? '，今年'+age + '岁' : ''}，家住${address}。现在正在${hospitalName}住院治疗，目前病情为：${patient_disease}，我妈妈这一生辛劳一辈子，妈妈是我们家的顶梁柱，从来没想过坚强勇敢的妈妈会病倒，多么希望妈妈能一直健康陪着我们。\n妈妈突然发病，到医院后被诊断为${patient_disease}，后续的治疗费用很高，我都不敢想象以后的治疗之路会有多曲折，不到万不得已，我们也不想发起筹款，可庞大的治疗费用。让我们寸步难行。无奈之下只能求助于宠物筹发起筹款。` +
                `\n\n求助呼吁：\n` +
                `希望大家能够帮助我妈妈渡过这次难关！ 希望您能帮忙转发扩散这条求助信息，让爱心传递到每个角落。在这里，深深感谢每一位帮助过我们的亲朋好友，感谢每一位无私转发的爱心人士。授人玫瑰，手有余香;奉献爱心，收获希望。我们急需您的帮助！望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!`
        },
        {
            code: `103`,
            pronoun: `她`,
            text: `羊羔知跪乳，乌鸦反哺亲，打扰大家了，麻烦大家看到信息的，帮忙转发一下我为挽救母亲发的求助信息，你的举手之劳能为我母亲带来生的希望，麻烦认识我的亲戚朋友实名验证一下情况属实，跪谢。` +
                `\n\n患者病情介绍：\n` +
                `大家好，我的母亲叫${patient_name}${age ? '，今年'+age + '岁' : ''}，我们家住${address}。从小母亲对我特别关爱，虽然家庭不富裕，但他总是把最好的都留给我，这其中母亲承受的艰辛，我都一直看在眼里，记在心里。\n原本以为我有了经济能力，母亲就可以轻松一点了，但是事与愿违，疾病的到来让我们的家庭陷入深深的困境！我们带着母亲到医院做了各项检查，母亲被确诊为${patient_disease}，我们家人听到这个结果后万分伤心，经过家人们一起商量，就算砸锅卖铁也要进行治疗，如果不治疗这将是我一生的遗憾。医生说要想治疗，这个高额的治疗费真的难倒了我们！ 思量再三，万般无奈下，我发起这次求助。` +
                `\n\n求助呼吁：\n` +
                `恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!我们急需您的帮助！\n望好心人士伸手相助，多一份转发就多一份希望，接力点亮生命的希望之光!恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },

        {
            code: `104`,
            pronoun: `她`,
            text: `树欲静而风不止，子欲养而亲不待，如果不是万不得已，真不愿在这打扰大家。` +
                `\n\n患者病情介绍：\n` +
                `家住${address}，我的母亲${patient_name}${age ? '，今年'+age + '岁' : ''}，因患${patient_disease}病情严重，现在医院治疗，为了给母亲争取一线生机，万般无奈在此求助。\n 母亲出现身体不适，我们到医院治疗，被确诊为${patient_disease}。大夫说母亲的病情比较严重，后续治疗周期长且费用巨大。` +
                `\n\n患者家庭情况：\n` +
                `我们家都是普通的家庭，收入非常有限。谁知道母亲突然得了这个病。这让我们本就不富裕的家庭更是雪上加霜！这半年来为了给母亲看病，不仅花光了家里本就不多的积蓄，现在后续的治疗费成了家里的大问题。` +
                `\n\n求助呼吁：\n` +
                `母亲操劳一生养育我们不容易，我们就算再困难也要给母亲看病！ 在没有办法的情况下，我们才借助宠物筹平台，想通过宠物筹，让更多的爱心人士看到我们，帮帮我们，谢谢大家,那怕您的一次转发，也是让我们坚持下去的理由，谢谢大家了！\n无论结果如何，我们以后一定要回报社会，将大家的爱心传递下去！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },
        {
            code: `105`,
            pronoun: `她`,
            text: `花要叶扶，人要人帮；赠人玫瑰，手有余香；滴滴甘霖，汇成海洋。` +
                `\n\n患者病情介绍：\n` +
                `我是给母亲${patient_name}发起筹款的${age ? '，她今年'+age + '岁' : ''}，我们家住在${address}，我们以前的日子还算幸福的，靠着我打工也能维持我家的基本生活。\n可是生活并不是那么一帆风顺，母亲开始觉得身体不适，来到${hospitalName}检查，诊断为${patient_disease}。需要做手术，但做这个手术费用很高，后面还有其他费用，住院费下来可能要花更多。所以我们在医院的附近租的房子，现在隔天都过来这边治疗。` +
                `\n\n患者家庭情况：\n` +
                `我们也不想遇到这样的事情，可是我母亲这边已经得病了，只能是硬着头皮一定要把这个手术做了。一定要为母亲的后半生想办法，哪怕就是家里面砸锅卖铁，现在已经借遍了所有的亲朋好友，压力太大。` +
                `\n\n求助呼吁：\n` +
                `我们在宠物筹平台上也帮助了很多人，所以我们要是没有走到困境，我们是一定不会发宠物筹筹款的。 万般无奈之下发起宠物筹筹款，万般感谢大家帮助我们家转发扩散。祝愿每一位好心人幸福健康！\n恳请各位好心人士伸手相助，多多转发！感谢大家！多多多证实，我们需要您的帮助！多一份转发就多一份希望，接力点亮生命的希望之光!恳请各位好心人转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },
        {
            code: `106`,
            pronoun: `她`,
            text: `明天和意外不知道哪个先来，平时只在手机上看到要给他人捐款，没想有一天，我自己也需要在这个平台上发起筹款。这次的意外让我知道，生命如此脆弱。在这次大疾病面前，谁都不能反抗。斟酌再三，带着复杂和感恩的心情，写下这封求助信求助大家。` +
                `\n\n患者病情介绍：\n` +
                `大家好，现在这里是为身患${patient_disease}的妈妈向您们求助！妈妈叫${patient_name}${age ? '，今年'+age + '岁' : ''}，我们家住${address}，爸妈都是朴实的农民，倾尽所有供养我读书成人，原本想着等着慢慢发展，可以好好赚钱报答她们的，但没想到意外来的如此之快。\n妈妈突然身体不适，赶紧将她送往${hospitalName}，经检查确诊${patient_disease}， 我和爸爸每天守在病房门外，只得默默为她鼓励打气。至今医生告诉我们，病人情况至今仍旧危机，后续治疗还需要多久，仍不能确定，让我们做好长期打算。` +
                `\n\n求助呼吁：\n` +
                `治疗至今，说实话，目前的费用实在令我压力山大，但不管未来如何，只要我还在，只要能救妈妈的命，只要还有一线治愈的机会我们都不会放弃。子欲养而亲常在是每个做子女的愿望！\n但实为事大力薄，医院每天都在催费，反复斟酌后发起宠物筹求助，希望大家能听到做为孩子拯救母亲的呼喊！也感恩好心人士的伸手，可以多多转发，每一次转发对我，我妈妈来说都是生命的一次接力！`
        },
        {
            code: `107`,
            pronoun: `她`,
            text: `我是为身患${patient_disease}，现在${hospitalName}治疗的妈妈发起此次宠物筹筹款，恳请社会上的爱心人士救救我的妈妈！` +
                `\n\n患者病情介绍：\n` +
                `我们家住${address}，是一个普通的农村家庭，我平时靠打工为生，虽然日子平凡普通，但是一家人平安健康的在一起也是很幸福的。\n意外的发生，从不提前告知。我的妈妈患者突然抽搐发烧，没有意识，送到我们县里的医院，经过检查，初步诊断为${patient_disease}，让赶快往上级医院转诊治疗，随后120救护车拉着我的妈妈来到了${hospitalName}医院，因为发烧，在隔离病房待了一天后退烧转到了病房，却没想到今天早上做检查的时候突然呼吸急促，赶快转到了重症监护室！\n直到此时此刻我都是懵的，我实在无法相信我的妈妈现在躺在重症监护室接受治疗，我守候在重症监护室外向上天祈祷妈妈快快好起来！无奈，我们家只是个普通的农村家庭，面对监护室每天高昂的花费实在是无力承担了` +
                `\n\n求助呼吁：\n` +
                `万般无奈之下发起宠物筹筹款，真诚的希望各位好心人帮帮我，感谢您们的雪中送炭，您的每次转发，对我的家庭来说是莫大的帮助，愿每位好心人身体健康，万事如意！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },
        {
            code: `108`,
            pronoun: `她`,
            text: `感谢大家抽出时间来阅读这一封求助信，给我们这个家一丝丝的温暖!非常抱歉用这样的方式打扰到您！` +
                `\n\n患者病情介绍：\n` +
                `患者叫${patient_name}，是我的母亲${age ? '，今年'+age + '岁' : ''}，在${hospitalName}被诊断出${patient_disease}。我们家在${address}，我们是勤劳朴实善良的农民，虽然日子苦点，但是是幸福的快乐的，因为有很多人关心着我们。` +
                `\n\n患者家庭情况：\n` +
                `家境贫寒，突遇重病，曾几何时，我也和您一样从不相信疾病会与亲人对号入座，可是上天就是如此残忍，突如其来的一场大病压的我们全家连气都喘不过来！\n病倒一人，目前家庭状况已经不能维持治疗，医院里花钱如流水，本不想麻烦大家，我们就是普通家庭，父母也都是老实本分的农民，医院也一直催缴这治疗费用，亲戚朋友也都在尽心尽力的帮我们！可是治疗花费还是有很大的缺口！` +
                `\n\n求助呼吁：\n` +
                `实在没有办法了只能想到求助宠物筹平台，恳请好心人帮帮我渡过难关。帮帮我的母亲！\n您看到这里的时候，或许会为我的家庭情况而产生怜悯之心，更希望您能给我更多的鼓励！请求各位好心人了，帮我度过难关，您的一份情我记在心里，等她病好了我们也会像你们一样去帮助困难之人，感谢感恩。\n人间有大爱，感谢您的帮助 感谢您带给我们的希望 妈妈好起来吧，我们都需要您！多一份转发就多一份希望，接力点亮生命的希望之光！恳请各位好心人士伸手相助，多多转发，您的每一次转发对我们都至关重要，每一次转发对我们来说都是莫大的帮助!`
        },
    ];
    const otherTemplates = [{
            code: `25`,
            text: `大家好，${pronoun}是${patient_name}，${age ? age + '岁，' : ''}为了治疗${patient_disease}，眼看着他们一家变卖房产、四处求助，却还是筹不到${project_amount}元的医疗费，一愁莫展……这不是电视剧里的戏剧冲突，这就是${patient_name}一家生活的真实写照，请看到的好心人希望好心人帮帮他们，我先代他们谢谢你们了，真的非常感谢！` +
                `\n\n患者病情介绍：\n` +
                `${diagnosis_date ? diagnosis_date + '，' : '最近，'}${patient_name}在${hospitalName}里被诊断出${patient_disease}，从那一刻开始，这个家庭就仿佛被恶魔盯上了。${patient_name}的病情发展的非常迅速，根本没给家人思考的机会。起初，只是身体上有些发烧、疼痛的小状况，家里人并没有当回事。但谁知道，${patient_name}却一天比一天看着憔悴，家里人心想不能再拖了，这才去医院确诊了。好在，他们还是幸运的。在医院的治疗下，${patient_name}有了明显的好转。虽然每次发病，${patient_name}都痛不欲生，但只要有活着的希望，${pronoun}就想要坚持。然而，高昂的医药费${project_amount}元像是一只拦路虎，把全家的希望无情的挡在门外。` +
                `\n\n患者家庭情况：\n` +
                `为了给${patient_name}治病，一家人已经散尽家财。疾病面前人是多么的脆弱，而贫穷就是压倒人的最后一根稻草。${patient_name}想着高昂的医疗费，说过无数次放弃治疗，${pronoun}不想看着家人为${pronoun}失去好好活着的权力。` +
                `\n\n求助呼吁：\n` +
                `坎坷的命运已经压垮了这家人，他们痛苦迷茫，不知上天为何这样对待自己，但他们依然执着，不愿意就这样缴械投降。然后在高昂的治疗费用面前，他们依然心有余而力不足。这个可怜的家庭需要你的帮助，哪怕只是一分一厘，只是一次小小的转发，对于他们来说都是生命的希望，我代他们先谢谢你们了！`
        },
        {
            code: `26`,
            text: `${pronoun}是${patient_name}，${age ? age + '岁，' : ''}来自${address}从小家境清贫但胜在家庭氛围和谐，然而，突出其来的一场厄运却将${patient_name}和${pronoun}最爱的的家人们置于水深火热之中，因为身体一向健康的${pronoun}被检查出${patient_disease}，不仅如此，还需要准备${project_amount}元治疗费，${cost_amount ? '现在已经花了'+cost_amount + '元。' : ''}但家里根本拿不出来，家人焦虑得一夜之间头发白了大半……希望好心人能帮帮${pronoun}，救人一命胜造七级浮屠，谢谢你们！` +
                `\n\n患者病情介绍：\n` +
                `我永远记得那一天，${diagnosis_date ?diagnosis_date : '最近'}因为那一天${pronoun}拿到了人生中最沉重的病历，也是从那一刻起，${patient_name}就感觉自己给家庭带来了灾难，但疾病并没有因自责而停止恶化，很快，身体的各种不适就前赴后继的向${pronoun}扑来，而且随着时间推移越来越严重。但花出去的钱却像流水一样，很快就掏空了这个本就清贫的家，${patient_name}并没有放弃，为筹备${project_amount}元的医药费四处奔走，求助了所有的亲戚朋友，但是最终筹来的钱对于巨额的医疗费依然是杯水车薪。` +
                `\n\n患者家庭情况：\n` +
                `${patient_name}家庭条件一般，父母供${pronoun}读书已经欠下了很多的债务，本以为${pronoun}工作了，好日子就到了跟前，没想到却得了${patient_disease}，还给他们带来了${project_amount}元治疗费的困境。有一天深夜起床上卫生间，我在楼道里听到${patient_name}无助的抽泣，看到${pronoun}难过的样子，我心里也很难受。` +
                `\n\n求助呼吁：\n` +
                `我不明白上天为什么要让我的淳朴、善良的${patient_name}承受这一切，如果可以，我希望能承担他们所有的痛苦，连同${pronoun}的绝望、无助、衰老、恐惧。所以我恳求大家伸出援手，帮帮我那饱受磨难的${patient_name}，他日有幸康复，必将竭尽全力回报社会，谢谢你们！`
        },
        {
            code: `109`,
            text: `各位好心人大家好！生病的是${patient_name}，家在${address}。被检查出了${patient_disease}，在${hospitalName}接受治疗。这次患病给整个家庭带来不小的打击，实在是不得已的情况，才决定在宠物筹上求助。恳请各位好心人士伸手相助，让患者有机会继续接受治疗！对于我们来说，您的每一次转发都至关重要，都是莫大的帮助，谢谢大家！`
        },
        {
            code: `110`,
            text: `各位好心人，你们好，${pronoun}叫${patient_name}，${age ? '今年' + age + '岁，' : ''}${address}人，被医生确诊为${patient_disease}，现在正在${hospitalName}接受治疗，现在正在顽强的对抗病魔。${pronoun}人生的道路应该还很长，${pronoun}的家庭不能没有${pronoun}，${pronoun}身边每个人都不希望${pronoun}离开这个世界。聚沙成塔，诚望各位心怀善意的好心人能帮患者一把。无论是您慷慨解囊，还是帮忙转发，还是一句加油，都是在给患者一丝康复的希望，谢谢，祝愿好人一生平安！`
        },
        {
            code: `111`,
            text: `非常感谢各位在百忙之中打开这封求助信，了解我们的情况，恳请您花一点时间看完。生病的是${patient_name}，来自${address}。在${hospitalName}检查时，最终被诊断为${patient_disease}。突如其来的疾病打乱了正常生活，让整个家庭陷入困境。诚望各位心怀善意的好心人能帮患者一把。无论是您慷慨解囊，还是帮忙转发，还是一句加油，都是在给患者一丝康复的希望！谢谢，祝愿好人一生平安！`
        },
        {
            code: `112`,
            text: `各位好心人大家好！ ${pronoun}叫${patient_name}，${pronoun}来自${address}，我得知${pronoun}不幸被检查出得了${patient_disease}，${pronoun}在${hospitalName}接受治疗已经有一段时间了，${pronoun}现在也每天都严格按照医生的叮嘱好好吃药，顽强的对抗病魔。恳请大家可以伸出援手，让${pronoun}有条件继续接受治疗，尽快好起来，家人和朋友都在等着${pronoun}康复，谢谢你们了！`
        },
    ];

    const templateData = {
        self: selfTemplates,
        parent: parentTemplates,
        child: childTemplates,
        couple: coupleTemplates,
        other: otherTemplates,
    }
    return templateData
}

export default randomDec;